/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const SaveDirectAccessModal = ({
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
  moduleSlug = "",
  selectedAaccessId = null,
  setSelectedAaccessId = () => {},
}) => {
  const location = useLocation();

  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation

  const [accessTitle, setaccessTitle] = useState("");
  const [accessDetails, setaccessDetails] = useState("");
  const [accessModule, setaccessModule] = useState(moduleSlug);
  const [errorMessage, seterrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  //get direct access details
  const getDirectAccessDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DIRECT_ACCESS_DETAILS +
        `/${selectedAaccessId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setaccessTitle(response.data.title);
        setaccessDetails(response.data.description);
        setaccessModule(response.data.moduleslug);
      }
    } catch (error) {
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //function for saving access
  const saveAccessHandler = async () => {
    if (accessTitle === "") {
      seterrorMessage(t("Please enter access title"));
    } else {
      seterrorMessage("");

      setIsSaving(true);
      try {
        let accessData = {
          moduleslug: accessModule,
          title: accessTitle,
          description: accessDetails,
          accessurl: location.pathname,
        };

        // console.log(accessData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (selectedAaccessId) {
          requestUrl +=
            url.API_UPDATE_DIRECT_ACCESS +
            `/${selectedAaccessId}?token=${token}`;

          response = await putData(requestUrl, accessData);
        } else {
          requestUrl += url.API_ADD_DIRECT_ACCESS + `?token=${token}`;

          response = await postData(requestUrl, accessData);
        }

        console.log(response);

        setIsSaving(false);

        if (response.status) {
          resetHandler();
          setMessageType("success");

          // close the modal

          let currentModal = document.querySelector("#saveDirectAccess");
          let modal = bootstrap.Modal.getInstance(currentModal);
          modal.hide();
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        seterrorMessage(error.message);
        setAlertMessage(error.message);
        setMessageType("error");
      }
      setShowAlert(true);
    }
  };

  const resetHandler = () => {
    setaccessTitle("");
    setSelectedAaccessId(null);
  };

  useEffect(() => {
    if (selectedAaccessId) {
      getDirectAccessDetails();
    }
  }, [selectedAaccessId]);

  return (
    <div
      className="modal fade"
      id="saveDirectAccess"
      tabIndex="-1"
      aria-labelledby="saveDirectAccess"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="saveDirectAccess">
              {t("Save Direct Access")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- user select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="urlname"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Access Name")}
                </label>
                <input
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter Access Name")}
                  value={accessTitle}
                  onChange={(e) => {
                    setaccessTitle(e.target.value);
                    seterrorMessage("");
                  }}
                />
              </div>
              {/* ----- user select section end ----- */}

              <div className="form-group mb-4">
                <label
                  htmlFor="slugID"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Details")}
                </label>
                <textarea
                  name="details"
                  id="details"
                  cols="30"
                  rows="6"
                  className="form-control border-0 shadow-none"
                  placeholder={t("Details")}
                  value={accessDetails}
                  onChange={(e) => setaccessDetails(e.target.value)}
                ></textarea>
              </div>

              {/* ----- role select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="role"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Access Url")}
                </label>
                <p className="form-control fs-sm shadow-none">
                  {location.pathname}
                </p>
              </div>
              {/* ----- role select section end ----- */}

              {/* ----- button section start ----- */}
              <div className="action d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {t("Cancel")}
                </Link>

                {errorMessage === "" ? null : (
                  <p className="text-danger">* {errorMessage}</p>
                )}

                <button
                  type="button"
                  className="btn btn-primary ms-auto"
                  onClick={saveAccessHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* ----- button section end ----- */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveDirectAccessModal;
