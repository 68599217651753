/* eslint-disable */
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

// import for excel download
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { Box, MenuItem } from "@mui/material";
//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import CRMLeadListHeader from "../Header/CRMLeadListHeader";
import CRMLeadListFilterPopup from "../Popup/CRMLeadListFilterPopup";
import AddLabelModal from "components/Common/Modal/AddLabelModal";

//import images
import { assetImages } from "constants";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";

//import common header objects
import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import ConversationNewMailPopup from "components/Common/Popup/ConversationNewMailPopup";
import AddToListModal from "components/Common/Modal/AddToListModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import AddAlertModal from "components/Common/Modal/AddAlertModal";
import AddFollowerModal from "components/Common/Modal/AddFollowerModal";
import LinkedinHelperModal from "components/Common/Modal/LinkedinHelperModal";
import VeriFyEmailModal from "../Modal/VeriFyEmailModal";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";
import SaveDirectAccessModal from "components/Common/Modal/SaveDirectAccessModal";

const CRMLeadListBody = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const { screenHeight } = useContext(GlobalProvider);

  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const [isLoading, setIsLoading] = useState(false);
  const [leadsDataList, setleadsDataList] = useState([]);

  const [followingLeadsCount, setfollowingLeadsCount] = useState(0);
  const [opportunitiesTotalCount, setopportunitiesTotalCount] = useState(0);
  const [totalNotesCount, settotalNotesCount] = useState(0);
  const [totalConversationCount, settotalConversationCount] = useState(0);

  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterLeadName, setFilterLeadName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [filterCompany, setFilterCompany] = useState("");
  const [filterPosition, setFilterPosition] = useState("");
  const [filterTransactionModule, setFilterTransactionModule] = useState("");
  const [filterTransactionElement, setFilterTransactionElement] = useState("");
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterMinSalary, setFilterMinSalary] = useState("");
  const [filterMaxSalary, setFilterMaxSalary] = useState("");
  const [filterLabels, setFilterLabels] = useState([]);
  const [filterSubsribeStatus, setFilterSubsribeStatus] = useState("");

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [selectLeadMails, setSelectLeadMails] = useState("");
  const [selectedLeadNames, setSelectedLeadNames] = useState("");

  const [isNoDefaultFilter, setIsNoDefaultFilter] = useState(false);

  const [isVerifying, setIsVerifying] = useState(false);

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  // paginatin ends -----------------------------------

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // download to excel
  const [excelData, setExcelData] = useState([]);

  const [enetredNumberForSelect, setEnetredNumberForSelect] = useState("");

  const [selectedNumberValidationMessage, setSelectedNumberValidationMessage] =
    useState("");

  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  //function for get all lead records
  const getAllLeads = async (isFilter) => {
    setRowSelection({});
    setSelectedLeadIds([]);
    setSelectLeadMails("");
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_LEADS +
        `?token=${token}&roleslug=${userInfo.role ? userInfo.role?.slug : ""}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterLeadName != "") {
        requestUrl = requestUrl + `&filtername=${filterLeadName}`;
      }

      if (filterEmail != "") {
        requestUrl = requestUrl + `&filteremail=${filterEmail}`;
      }

      if (filterPhone != "") {
        requestUrl = requestUrl + `&filterphone=${filterPhone}`;
      }

      if (filterLocation != "") {
        requestUrl = requestUrl + `&filterlocation=${filterLocation}`;
      }

      if (filterPosition != "") {
        requestUrl = requestUrl + `&filterposition=${filterPosition}`;
      }

      if (filterCompany != "") {
        requestUrl = requestUrl + `&filtercompany=${filterCompany}`;
      }

      if (filterMinSalary != "") {
        requestUrl = requestUrl + `&filterminsalary=${filterMinSalary}`;
      }

      if (filterMaxSalary != "") {
        requestUrl = requestUrl + `&filtermaxsalary=${filterMaxSalary}`;
      }

      if (filterTransactionModule != "") {
        requestUrl =
          requestUrl + `&filtertransactionmodule=${filterTransactionModule}`;
      }

      if (filterTransactionElement != "") {
        requestUrl =
          requestUrl + `&filtertransactionelement=${filterTransactionElement}`;
      }

      if (filterSkills.length > 0) {
        requestUrl = requestUrl + `&filterskills=${filterSkills}`;
      }

      if (filterLabels.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterLabels}`;
      }

      if (filterSubsribeStatus !== "") {
        requestUrl =
          requestUrl + `&filtersubsribestatus=${filterSubsribeStatus}`;
      }

      requestUrl += `&isfilter=${isFilter}`;

      console.log("url of lead list---------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in lead list------->", response);

      if (response.status) {
        setMessageType("success");
        setleadsDataList(response.data);

        //get follwing lead
        const followingLeads = response.data.filter((lead) =>
          lead.followers.includes(userInfo._id)
        );

        setfollowingLeadsCount(followingLeads.length);

        //get total opportunities
        const getSumOfOpportunities = response.data.reduce((acc, curr) => {
          return acc + curr.opportunitycount;
        }, 0);

        setopportunitiesTotalCount(getSumOfOpportunities);

        //get total notes
        const getSumOfNotes = response.data.reduce((acc, curr) => {
          return acc + curr.notecount;
        }, 0);

        settotalNotesCount(getSumOfNotes);

        //get total conversations
        const getSumOfConversations = response.data.reduce((acc, curr) => {
          return acc + curr.conversationcount;
        }, 0);

        settotalConversationCount(getSumOfConversations);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);

      setIsLoading(false);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectedLeadIds) {
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_LEAD +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl);

        if (response.status) {
          setMessageType("success");
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
      setShowAlert(true);
    }
    getAllLeads(false);
  };

  //verify emails
  const emailVerificationHandler = async () => {
    if (selectedLeadIds.length > 0) {
      setIsVerifying(true);
      try {
        let leadData = {
          leadids: selectedLeadIds,
        };

        let requestUrl =
          url.API_BASE_URL + url.API_VERIFY_LEAD_EMAILS + `?token=${token}`;

        const response = await postData(requestUrl, leadData);
        setIsVerifying(false);

        let myModal = document.querySelector("#veriFyEmail");
        let modal = bootstrap.Modal.getInstance(myModal);
        modal.hide();

        if (response.status) {
          setSelectedLeadIds([]);
          setRowSelection({});
          setMessageType("success");

          getAllLeads(false);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        console.log("error in email verification handler", error.message);
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please select at least one lead"));
    }
    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedLeadList = [...leadsDataList];

    const rowIndex = updatedLeadList.findIndex(
      (lead) => lead._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedLeadList[rowIndex].rank = value;
      setleadsDataList(updatedLeadList);
    }
  };

  //function for update rank
  const updateBulkLeadHandler = async (e) => {
    if (selectedLeadIds.length > 0) {
      try {
        const leadListToBeUpdate = leadsDataList.filter((item) =>
          selectedLeadIds.includes(item._id)
        );

        let leadData = {
          leadlist: leadListToBeUpdate,
        };

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_MASSIVE_LEAD + `?token=${token}`;

        const response = await putData(requestURL, leadData);

        console.log(response);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllLeads(false);
          }, 2500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
    }
    setShowAlert(true);
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterSubsribeStatus("");
    setfilterStartDate("");
    setfilterEndDate("");
    setFilterLeadName("");
    setFilterEmail("");
    setFilterLocation("");
    setFilterPhone("");
    setFilterCompany("");
    setFilterPosition("");
    setFilterTransactionModule("");
    setFilterTransactionElement("");
    setFilterSkills([]);
    setFilterMinSalary("");
    setFilterMaxSalary("");
    setFilterLabels([]);

    setEnetredNumberForSelect("");
    setSelectedNumberValidationMessage("");

    setReloadData(true);

    // reset pagination to default
    setLastPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };

  const afterEmailClose = () => {
    setRowSelection({});
    setSelectedLeadIds([]);
    setSelectLeadMails("");
  };

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "customid",
      header: t("Id"),
      size: 100,
    },
    {
      accessorKey: "tagnames",
      header: t("Label"),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagnames != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagnames.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
    {
      accessorKey: "fullname",
      header: t("Name"),
      size: 200,
    },
    {
      accessorKey: "rank",
      header: t("Rank"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {row.original.moderator.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ? (
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "100px" }}
              value={row.original.rank}
              onChange={(e) => rankInputChangeHandler(row, e.target.value)}
            />
          ) : (
            <p className="ps-3">{row.original.rank}</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: "email",
      header: t("Email"),
      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            width: "250px",
          }}
        >
          <span>{renderedCellValue}</span>
          {row.original.emailverified ? (
            <span className="d-block text-success material-symbols-outlined icon-md">
              check_circle
            </span>
          ) : (
            <span className="d-block text-danger material-symbols-outlined icon-md">
              cancel
            </span>
          )}
        </Box>
      ),
    },
    {
      accessorKey: "linktrackerstring",
      header: t("Link Tracker"),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.linktrackerstring != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.linktrackerstring
                .split(" , ")
                .map((tracker, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tracker}
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      ),
    },
    {
      accessorKey: "subscribetomail",
      header: t("Subscribe to Mail"),
    },
    {
      accessorKey: "ownername",
      header: t("Owner"),
    },
    {
      accessorKey: "followersnames",
      header: t("Followers"),
      size: 250,
    },
    {
      accessorKey: "source",
      header: t("Source"),
    },
    {
      accessorKey: "position",
      header: t("Position"),
    },
    {
      accessorKey: "company",
      header: t("Company"),
    },
    {
      accessorKey: "phone",
      header: t("Phone"),
    },
    {
      accessorKey: "skillsnames",
      header: t("Skills"),
    },
    {
      accessorKey: "location",
      header: t("Location"),
    },
    {
      accessorKey: "leadlastcontactdate",
      header: t("Last Contact Date"),
    },
    {
      accessorKey: "lastcontactsubject",
      header: t("Last Contact Subject"),
    },
    {
      accessorKey: "lastcontactchannel",
      header: t("Last Contact Channel"),
    },
    {
      accessorKey: "leadlastresponsedate",
      header: t("Last Response Date"),
    },
    {
      accessorKey: "lastresponsesubject",
      header: t("Last Response Subject"),
    },
    {
      accessorKey: "lastresponsechannel",
      header: t("Last Response Channel"),
    },
    {
      accessorKey: "transactionid",
      header: t("Last Transaction"),
    },
    {
      accessorKey: "module",
      header: t("Transaction Mod."),
    },
    {
      accessorKey: "element",
      header: t("Trans. Element"),
    },
    {
      accessorKey: "transactionamount",
      header: t("Trans. Amt."),
    },
    {
      accessorKey: "leadtransactiondate",
      header: t("Trans. Date"),
    },
    {
      accessorKey: "leadcreateddate",
      header: t("Date"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(
    () => initialTableColumns,
    [leadsDataList, i18n.language]
  );

  /* Updates the column visibility state by toggling the visibility of the column with the given accessor key.*/
  const initialVisibilityState = initialTableColumns.reduce((acc, column) => {
    acc[column.accessorKey] = true;
    return acc;
  }, {});

  /* Represents the state of column visibility in the table.*/
  const [visibleColoumns, setVisibleColoumns] = useState(
    initialVisibilityState
  );

  //function for column visibility
  const onColumnVisiblityHandler = (newColumnState) => {
    if (typeof newColumnState === "function") {
      const newColumnStateName = newColumnState();
      setVisibleColoumns((prev) => ({ ...prev, ...newColumnStateName }));
    } else {
      setVisibleColoumns(newColumnState);
    }
  };

  //initialize the column order
  const initialColumnOrder = [
    "mrt-row-actions",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  //function for change column order
  const changeColumnOrderHandler = (changedOrder) => {
    setColumnOrder(changedOrder);
  };

  //function for selecting rows manually
  const recordSelectionHandler = () => {
    if (enetredNumberForSelect !== "" || enetredNumberForSelect > 0) {
      // console.log("Lead data leangth", leadsDataList.length);
      // console.log("Entered number ----->", enetredNumberForSelect);
      // console.log("Entered row data ----->", enetredNumberForSelect);

      if (enetredNumberForSelect <= leadsDataList.length) {
        const getLeadRows = leadsDataList
          .slice(0, enetredNumberForSelect)
          .map((row) => {
            return `${row._id}/${row.email}/${row.ownerid}/${row.fullname}`;
          });

        const selectedResult = {};

        getLeadRows.forEach((item) => {
          selectedResult[item] = true;
        });

        setRowSelection(selectedResult);
      } else {
        setSelectedNumberValidationMessage(
          t("Input number should not be greater than the number of leads")
        );
      }
    } else {
      setSelectedNumberValidationMessage(
        t("Please enter a valid input number")
      );
    }
  };

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      const selectedLeadIdArr = selectedIdsArray.map((id) => {
        const [leadId] = id.split("/");
        return leadId;
      });

      const selectLeadEmails = selectedIdsArray.map((id) => {
        const [, leadMail] = id.split("/");
        return leadMail;
      });

      const selectedLeadEmailString = selectLeadEmails.join(",");

      setSelectedLeadIds(selectedLeadIdArr);
      setSelectLeadMails(selectedLeadEmailString);

      if (selectedIdsArray.length === 1) {
        const leadNameObj = selectedIdsArray[0];

        const [, , , leadName] = leadNameObj.split("/");

        setSelectedLeadNames(leadName);
      } else {
        setSelectedLeadNames("");
      }
    } else {
      setSelectedLeadIds([]);
      setSelectLeadMails("");
    }
  }, [rowSelection]);

  // save the last pagination
  useEffect(() => {
    if (pagination.pageIndex > 0 || pagination.pageSize > 10) {
      setLastPagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (
      (filterStartDate !== "" && filterEndDate !== "") ||
      filterLocation != "" ||
      filterLeadName != "" ||
      filterEmail != "" ||
      filterCompany != "" ||
      filterPhone != "" ||
      filterPosition != "" ||
      filterMinSalary != "" ||
      filterMaxSalary != "" ||
      filterTransactionModule != "" ||
      filterTransactionElement != "" ||
      filterSkills.length > 0 ||
      filterLabels.length > 0 ||
      filterSubsribeStatus !== ""
    ) {
      getAllLeads(true);
    }
  }, [
    filterSubsribeStatus,
    filterStartDate,
    filterEndDate,
    filterLocation,
    filterLeadName,
    filterEmail,
    filterCompany,
    filterPhone,
    filterPosition,
    filterMinSalary,
    filterMaxSalary,
    filterSkills,
    filterLabels,
    filterTransactionModule,
    filterTransactionElement,
  ]);

  useEffect(() => {
    if (isNoDefaultFilter) {
      getAllLeads(false);
    }
  }, [isNoDefaultFilter]);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getAllLeads(false);
      setReloadData(false);
    }
  }, [reloadData]);

  const refreshRecords = () => {
    resetFilterData();
    setVisibleColoumns(initialVisibilityState);
    setColumnOrder(initialColumnOrder);
  };

  /* Closes the alert by updating the state variables.*/
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  //download as excel
  const downloadAsExcel = () => {
    if (leadsDataList.length > 0) {
      const createRowData = (apiData) => [
        apiData.customid,
        apiData.fullname,
        apiData.email,
        apiData.subscribetomail,
        apiData.ownername,
        apiData.followersnames,
        apiData.source,
        apiData.position,
        apiData.company,
        apiData.phone,
        apiData.skillsnames,
        apiData.location,
        apiData.lastcontactdate,
        apiData.lastcontactsubject,
        apiData.lastcontactchannel,
        apiData.lastresponsedate,
        apiData.lastresponsesubject,
        apiData.lastresponsechannel,
        apiData.transactionid,
        apiData.module,
        apiData.element,
        apiData.transactionamount,
        apiData.transactiondate,
        apiData.leadcreateddate,
        apiData.leadupdateddate,
        apiData.tagnames,
      ];

      const filteredList =
        selectedLeadIds.length > 0
          ? leadsDataList.filter((item) => selectedLeadIds.includes(item._id))
          : leadsDataList;

      filteredList.map((apiData) => {
        console.log(apiData);
        const rowData = createRowData(apiData);
        setExcelData((prevState) => [...prevState, rowData]);
      });
    }
  };

  const createExcel = () => {
    const fileName = "leads";

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const Heading = [
      [
        "ID",
        "Name",
        "Email",
        "Subscribe To Mail",
        "Owner",
        "Followers",
        "Source",
        "Position",
        "Company",
        "Phone",
        "Skills",
        "Location",
        "Last Contact Date",
        "Last Contact Subject",
        "Last Contact Channel",
        "Last Response Date",
        "Last Response Subject",
        "Last Response Channel",
        "Last Transaction",
        "Transaction Mod.",
        "Trans. Element",
        "Trans. Amt.",
        "Trans. Date",
        "Date",
        "Updated Date",
        "Labels",
      ],
    ];

    const ws = XLSX.utils.json_to_sheet(excelData, {
      origin: "A2",
      skipHeader: true,
    });

    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const filedata = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(filedata, fileName + fileExtension);
  };

  useEffect(() => {
    if (excelData.length > 0) {
      createExcel();
      setExcelData([]);
    }
  }, [excelData]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_LEAD"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Leads")}
          />
          <div className="container-fluid px-lg-5">
            <CRMLeadListHeader
              userInfo={userInfo}
              reloadLeadList={refreshRecords}
              downloadAsExcel={downloadAsExcel}
              enetredNumberForSelect={enetredNumberForSelect}
              setEnetredNumberForSelect={setEnetredNumberForSelect}
              setSelectedNumberValidationMessage={
                setSelectedNumberValidationMessage
              }
              recordSelectionHandler={recordSelectionHandler}
              selectedNumberValidationMessage={selectedNumberValidationMessage}
              bulkUpdateHandler={updateBulkLeadHandler}
              leadsDataList={leadsDataList}
              followingLeadsCount={followingLeadsCount}
              opportunitiesTotalCount={opportunitiesTotalCount}
              totalNotesCount={totalNotesCount}
              totalConversationCount={totalConversationCount}
            />

            {/* <div className="mb-3 d-flex gap-2 align-items-center">
              <div className="crm_select_input">
                <input
                  className="form-control shadow-none"
                  placeholder={t("Enter number for select records")}
                  type="number"
                  min={0}
                  value={enetredNumberForSelect}
                  onChange={(e) => {
                    setEnetredNumberForSelect(e.target.value);
                    setSelectedNumberValidationMessage("");
                  }}
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={recordSelectionHandler}
              >
                {t("Select")}
              </button>

              {selectedNumberValidationMessage === "" ? null : (
                <div className="error-message ms-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined icon-fill">
                      warning
                    </span>
                    <span>{selectedNumberValidationMessage}</span>
                  </p>
                </div>
              )}
            </div> */}

            {/* ----- lead list table and pagination section start ----- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={leadsDataList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) =>
                    `${row._id}/${row.email}/${row.ownerid}/${row.fullname}`
                  } // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                  }} //pass our managed row selection state to the table to use
                  onPaginationChange={setPagination} // set pagination
                  initialState={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                  }}
                  // enableRowActions // show the action column
                  enableColumnOrdering={true}
                  renderRowActionMenuItems={({ row }) => {
                    const [leadId, emailId, ownerId, leadName] =
                      row.id.split("/"); // Extracts the _id part

                    const menuItems = [
                      <MenuItem key="details">
                        <Link
                          className="dropdown-item"
                          to={`/admin/crm/lead/information/${leadId}`}
                        >
                          {t("View Details")}
                        </Link>
                      </MenuItem>,
                    ];

                    if (
                      userInfo.role.slug === "ADMIN" ||
                      userInfo.role.slug === "SUPER_ADMIN" ||
                      userInfo._id.toString() === ownerId.toString()
                    ) {
                      menuItems.splice(
                        2,
                        0,
                        <MenuItem key="leadtask">
                          <Link
                            className="dropdown-item"
                            to={`/admin/crm/lead/addtask/${leadId}`}
                          >
                            {t("View Task")}
                          </Link>
                        </MenuItem>,
                        <MenuItem key="leadmail">
                          <Link
                            className="dropdown-item"
                            to={`/admin/crm/lead/communication/${leadId}`}
                          >
                            {t("View Communication")}
                          </Link>
                        </MenuItem>
                      );
                    }

                    if (
                      userInfo.role.slug === "ADMIN" ||
                      userInfo.role.slug === "SUPER_ADMIN" ||
                      (moduleAccess.includes("MOD_CRM") &&
                        moduleAccess.includes("MOD_OPPORTUNITY"))
                    ) {
                      menuItems.splice(
                        3,
                        0,
                        <MenuItem key="leadopportunity">
                          <Link
                            className="dropdown-item"
                            to={`/admin/crm/lead/opportunities/${leadId}`}
                          >
                            {t("View Opportunities")}
                          </Link>
                        </MenuItem>
                      );
                    }

                    if (
                      userInfo.role.slug === "ADMIN" ||
                      userInfo.role.slug === "SUPER_ADMIN" ||
                      userInfo._id.toString() === ownerId.toString()
                    ) {
                      menuItems.push(
                        <MenuItem key="update">
                          <Link
                            className="dropdown-item"
                            to={`/admin/crm/lead/save/${leadId}`}
                          >
                            {t("Update Lead")}
                          </Link>
                        </MenuItem>
                      );
                    }

                    return menuItems;
                  }} // action columns menu items
                  onColumnVisibilityChange={onColumnVisiblityHandler}
                  onColumnOrderChange={changeColumnOrderHandler}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
            {/* ----- lead list table and pagination section start ----- */}
          </div>
          {/* ----- all pop up render here ----- */}
          <CRMLeadListFilterPopup
            moduleName={"crmlead"}
            visibleColoumns={visibleColoumns}
            setVisibleColoumns={setVisibleColoumns}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            filterSubsribeStatus={filterSubsribeStatus}
            setFilterSubsribeStatus={setFilterSubsribeStatus}
            filterStartDate={filterStartDate}
            setfilterStartDate={setfilterStartDate}
            filterEndDate={filterEndDate}
            setfilterEndDate={setfilterEndDate}
            filterLeadName={filterLeadName}
            setFilterLeadName={setFilterLeadName}
            filterEmail={filterEmail}
            setFilterEmail={setFilterEmail}
            filterPhone={filterPhone}
            setFilterPhone={setFilterPhone}
            filterLocation={filterLocation}
            setFilterLocation={setFilterLocation}
            filterCompany={filterCompany}
            setFilterCompany={setFilterCompany}
            filterPosition={filterPosition}
            setFilterPosition={setFilterPosition}
            filterTransactionModule={filterTransactionModule}
            setFilterTransactionModule={setFilterTransactionModule}
            filterTransactionElement={filterTransactionElement}
            setFilterTransactionElement={setFilterTransactionElement}
            filterSkills={filterSkills}
            setFilterSkills={setFilterSkills}
            filterMinSalary={filterMinSalary}
            setFilterMinSalary={setFilterMinSalary}
            filterMaxSalary={filterMaxSalary}
            setFilterMaxSalary={setFilterMaxSalary}
            filterLabels={filterLabels}
            setFilterLabels={setFilterLabels}
            isFilterReset={isFilterReset}
            setIsFilterReset={setIsFilterReset}
            setIsNoDefaultFilter={setIsNoDefaultFilter}
          />
          {/* ------ add label modal render here ----- */}
          <AddLabelModal
            selectedIds={selectedLeadIds}
            moduleName="crmleadlist"
            afterTagModalClose={() => {
              setTimeout(() => {
                getAllLeads(false);
              }, 2500);
            }}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* ------ new mail popup render here ----- */}
          <ConversationNewMailPopup
            contactPersonEmail={selectLeadMails}
            reloadList={afterEmailClose}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* ------ add to list modal render here ----- */}
          <AddToListModal
            moduleName="lead"
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
            selectedIds={selectedLeadIds}
            afterModalClose={() => {
              setSelectedLeadIds([]);
              setRowSelection({});
            }}
          />

          <AddFollowerModal
            moduleName="crmleadlist"
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
            selectedIds={selectedLeadIds}
            afterModalClose={() => {
              setTimeout(() => {
                getAllLeads(false);
              }, 2500);
            }}
          />

          <VeriFyEmailModal
            isVerifying={isVerifying}
            onVeriFy={emailVerificationHandler}
            onCancelVeriFy={() => {
              setSelectedLeadIds([]);
              setRowSelection({});
            }}
          />

          <AddAlertModal
            selectedIds={selectedLeadIds}
            alertSubject={selectedLeadNames}
            afterModalClose={() => {
              setSelectedLeadIds([]);
              setRowSelection({});
            }}
            moduleName={"lead"}
          />

          <LinkedinHelperModal />
          {/* ----------- delete warning modal ------------ */}
          <DeleteWarningModal
            onCancelDelete={() => {
              setRowSelection({});
              setSelectedLeadIds([]);
            }}
            onDelete={changeStatusHandler}
            moduleName={t("lead")}
          />

          <SaveDirectAccessModal
            moduleSlug="MOD_LEAD"
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default CRMLeadListBody;
