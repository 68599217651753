/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import ChallengeResponseLeftPanel from "./LeftPanel/ChallengeResponseLeftPanel";
import ChallengeResponseRightPanel from "./RightPanel/ChallengeResponseRightPanel";

import ResponseCommentPopup from '../Common/Popup/ResponseCommentPopup';

import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import {
  challengeHeaderLinks,
  challnegeBreadCrumbText,
  getJobResponseStatus,
} from 'helper/ChallengeHelper/ChallengeHelper';

import { useTranslation } from 'react-i18next';

const ChallengeResponseDetailsBody = () => {

  const params = useParams();
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');
  const { t, i18n } = useTranslation(); //for translation

  // common header object, by challenge id
  const commonHeaderObject = challengeHeaderLinks(params.id, t);

  const challengeResponseId = params.rid;

  const [challengeResponseDetails, setChallengeResponseDetails] = useState(null);

  const [cvId, setCvId] = useState(null);

  // approval and status management
  const [approvalStatus, setapprovalStatus] = useState('');
  const [selectedHiringProcessId, setSelectedHiringProcessId] = useState(null);
  const [approveComments, setapproveComments] = useState('');

  
 
  //function for get project details
  const getChallengeResponseDetails = async () => {

    //setIsLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_RESPONSE_DETAILS +
        `/${challengeResponseId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log('challenge response', response);

      if (response.status && response.data) {
        const data = response.data;
        setChallengeResponseDetails(data);

        setCvId(data.candidatecvid ? data.candidatecvid._id : null);

      }

      //setIsLoading(false);

    } catch (error) {
      console.log(error.message);
    }

  };

  //approval action
  const approvalHandler = async () => {
    try {
      let approvalData = {
        approvalstatus: approvalStatus,
        hiringprocessid: selectedHiringProcessId || null,
        comments: approveComments,
      };

      console.log(approvalData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_CHANGE_APPROVAL_STATUS +
        `/${challengeResponseId}?token=${token}`;

      const response = await putData(requestUrl, approvalData);

      console.log('Challange Activity Response', response);

      if (response.status) {
        getChallengeResponseDetails(challengeResponseId);
      }

    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (challengeResponseId) {
      getChallengeResponseDetails(challengeResponseId);
    }
  }, [challengeResponseId]);

  return (
   <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5 pt-3">

        {/* ---- common header ---- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t('Responses')}
          />
        )}

        <div className="container-fluid px-lg-5">
          <div className="row">
            {/* save or update the response if required */}
            <div className="col-lg-8 mb-4 mb-lg-0">
              {challengeResponseDetails && (
                <ChallengeResponseLeftPanel 
                  challengeResponseDetails={challengeResponseDetails}
                  cvId={cvId}
                />
              )}
              
            
            </div>

            {/* show the information about the status of the response */}
            <div className="col-lg-4">  
              
              {challengeResponseDetails && (
                <ChallengeResponseRightPanel 
                  challengeResponseId={challengeResponseId}
                  challengeResponseDetails={challengeResponseDetails}
                  cvId={cvId}  
                  setapprovalStatus={setapprovalStatus}
                  setSelectedHiringProcessId={setSelectedHiringProcessId}
                  setapproveComments={setapproveComments}
                />
              )}

              

            </div>

          </div>
        </div>
      </section>
      

      {/* Reject status ------------------------- */}
      {challengeResponseDetails && (
        <ResponseCommentPopup
          approvalStatus={approvalStatus}
          setapproveComments={setapproveComments}
          approveComments={approveComments}
          approvalHandler={approvalHandler}
        />
      )}
      

    </div>
  );
};

export default ChallengeResponseDetailsBody;
