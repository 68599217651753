/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "utils/Gateway";

const SkillPointModal = ({
  skillPointIndex,
  setSkillPointIndex,
  skillPointData,
  setSkillPointData,
  skillPointListBlock,
  setSkillPointListBlock,
}) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");

  const [allSkills, setAllSkills] = useState([]);

  //function for get all category
  const getAllSkills = async () => {
    try {
      const parentSlug = "skills";

      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setAllSkills(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for add block
  const addSkillPointHandler = () => {
    setSkillPointListBlock([...skillPointListBlock, skillPointData]);
    closeModalHandler();
  };

  //function for edit education block
  const editSkillpointBlockHandler = () => {
    const updatedSkillPointList = [...skillPointListBlock];
    updatedSkillPointList[skillPointIndex] = skillPointData;
    setSkillPointListBlock(updatedSkillPointList);
    closeModalHandler();
  };

  //close modal handler
  const closeModalHandler = () => {
    setSkillPointData({
      skill: null,
      skillvalue: null,
      skillname: "",
      points: "",
      experienceyear: "",
    });
    setSkillPointIndex(null);
  };

  useEffect(() => {
    getAllSkills();
  }, []);

  return (
    <div className="process_modal builder_modal">
      
      <div
        className="modal fade"
        id="skill_modal"
        tabIndex="-1"
        aria-labelledby="addDetailsLabel"
        aria-hidden="true"
      >

        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>Save Skill point</h3>
              </div>
              <button
                type="button"
                className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_innr">
                  <div className="form-group">
                    <label> Select Skill</label>
                    <Select
                      isClearable
                      placeholder="Select Skill"
                      options={allSkills}
                      value={skillPointData.skillvalue}
                      onChange={(val) => {
                        if (val) {
                          setSkillPointData((prevData) => ({
                            ...prevData,
                            skillvalue: val,
                            skill: val.value,
                            skillname: val.label,
                          }));
                        } else {
                          setSkillPointData((prevData) => ({
                            ...prevData,
                            skillvalue: null,
                            skill: null,
                            skillname: "",
                          }));
                        }
                      }}
                    />
                  </div>

                  {/* <!-- ========== Start skill name Section ========== --> */}
                  {skillPointData.skillvalue ? null : (
                    <div className="form-group">
                      <label> Enter Skill Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Skill Name"
                        value={skillPointData.skillname}
                        onChange={(e) => {
                          setSkillPointData((prevData) => ({
                            ...prevData,
                            skillvalue: null,
                            skill: null,
                            skillname: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  )}
                  {/* <!-- ========== End skill name Section ========== --> */}

                  {/* point */}
                  <div className="form-group">
                    <label>Point (score between 0 - 10)</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Point"
                      max="10"
                      value={skillPointData.points}
                      onChange={(e) => {
                        setSkillPointData((prevData) => ({
                          ...prevData,
                          points: e.target.value,
                        }));
                      }}
                    />
                  </div>

                  {/* experience */}
                  <div className="form-group">
                    <label>Total Experience</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter experience"
                      value={skillPointData.experienceyear}
                      onChange={(e) => {
                        setSkillPointData((prevData) => ({
                          ...prevData,
                          experienceyear: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <button
                    type="button"
                    onClick={closeModalHandler}
                    data-bs-dismiss="modal"
                    className="btn gray"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={
                      skillPointIndex != null
                        ? editSkillpointBlockHandler
                        : addSkillPointHandler
                    }
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillPointModal;
