/* eslint-disable */
import React, { useEffect } from "react";

//** import components */
import Header from "components/Common/Header/Header";
import DashboardBody from "components/DashboardComponents/GlobalDashboard/DashboardBody/DashboardBody";

const GlobalDashboard = () => {
  //assign browser title is dashboard
  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  return (
    <main id="app">
      <Header moduleName="dashboard" />

      {/* <!-- ========== Start main dashboard component Section ========== --> */}
      <DashboardBody />
      {/* <!-- ========== End main dashboard component Section ========== --> */}

   
    </main>
  );
};

export default GlobalDashboard;
