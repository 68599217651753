/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box } from "@mui/material";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

import { assetImages } from "constants";

import AddLabelModal from "components/Common/Modal/AddLabelModal";
import OpportunityHeader from "../Header/OpportunityHeader";
import SaveInvoicePopup from "components/Common/Popup/SaveInvoicePopup";
import InvoiceListFIlterPopup from "components/Common/Popup/InvoiceListFIlterPopup";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AddFollowerModal from "components/Common/Modal/AddFollowerModal";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";
import AddNewCrmOpportunityPopup from "../Popup/AddNewCrmOpportunityPopup";
import SaveDirectAccessModal from "components/Common/Modal/SaveDirectAccessModal";

const CrmOpportunityBody = () => {
  const moduleSlug = "opportunity";

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();
  const todayValue = new Date().toISOString().split("T")[0];
  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);

  const [followingOpportunityCount, setfollowingOpportunityCount] = useState(0);
  const [totalNotesCount, settotalNotesCount] = useState(0);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterStartingPrice, setfilterStartingPrice] = useState("");
  const [filterEndingPrice, setfilterEndingPrice] = useState("");
  const [filterTags, setfilterTags] = useState([]);
  const [filterPromisePercent, setfilterPromisePercent] = useState("");
  const [filterTitle, setfilterTitle] = useState("");
  const [filterOwners, setfilterOwners] = useState([]);
  const [filterFollowers, setfilterFollowers] = useState([]);

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [isNoDefaultFilter, setIsNoDefaultFilter] = useState(false);

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  // paginatin ends -----------------------------------

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all contacts
  const getInvoiceList = async () => {
    setRowSelection({});
    setSelectedInvoiceIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_MY_INVOICES +
        `?token=${token}&moduleslug=${moduleSlug}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterStartingPrice !== "" && filterEndingPrice !== "") {
        requestUrl += `&filterstartprice=${filterStartingPrice}&filterendprice=${filterEndingPrice}`;
      }

      if (filterTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      }

      if (filterPromisePercent !== "") {
        requestUrl = requestUrl + `&filterpercent=${filterPromisePercent}`;
      }

      if (filterOwners.length > 0) {
        requestUrl = requestUrl + `&filterowners=${filterOwners}`;
      }

      if (filterFollowers.length > 0) {
        requestUrl = requestUrl + `&filterfollowers=${filterFollowers}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterTags}`;
      }

      console.log("request url------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response ------>", response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setInvoiceList(response.data);

        //get follwing lead
        const followingOpportunity = response.data.filter((opportunity) =>
          opportunity.followers.includes(userInfo._id)
        );

        setfollowingOpportunityCount(followingOpportunity.length);

        //get total notes
        const getSumOfNotes = response.data.reduce((acc, curr) => {
          return acc + curr.notecount;
        }, 0);

        settotalNotesCount(getSumOfNotes);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for change status
  const changeStatusHandler = async () => {
    let responseArr = [];
    for (let selectedId of selectedInvoiceIds) {
      try {
        let invoiceData = {
          status: "0",
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_INVOICE +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, invoiceData);

        if (response.status) {
          responseArr.push(true);
          setMessageType("success");
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    }

    if (responseArr.length > 0) {
      setTimeout(() => {
        getInvoiceList();
      }, 1500);
    }
  };

  //material table columns array
  const initialTableColumns = [
    // {
    //   accessorKey: "#",
    //   header: t("Action"),
    //   enableColumnActions: false, // Hides the column action icon
    //   enableColumnDragging: false, // Hides the move icon
    //   enableSorting: false,
    //   Cell: ({ row }) => (
    //     <button
    //       className="action_btn_mui"
    //       data-bs-toggle="offcanvas"
    //       data-bs-target="#saveInvoice"
    //       aria-controls="saveInvoice"
    //       onClick={() => {
    //         setSelectedInvoiceId(row.original._id);
    //       }}
    //     >
    //       <span className="d-block material-symbols-outlined horz_icon">
    //         more_horiz
    //       </span>
    //     </button>
    //   ),
    // },
    {
      accessorKey: "tagstring",
      header: t("Label"),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagstring != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagstring.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
    {
      accessorKey: "customid",
      header: t("Id"),
    },
    {
      accessorKey: "title",
      header: t("Title"),
    },
    {
      accessorKey: "customername",
      header: t("Customer"),
    },
    {
      accessorKey: "amount",
      header: t("Price"),
    },
    {
      accessorKey: "invoicedate",
      header: t("Date"),
    },
    {
      accessorKey: "promisepercent",
      header: t("Percentage") + "(%)",
    },
    {
      accessorKey: "ownername",
      header: t("Owner"),
    },
    {
      accessorKey: "followersname",
      header: t("Followers"),
    },
    {
      accessorKey: "modulename",
      header: t("Module"),
    },

    // {
    //   accessorKey: "#",
    //   header: t("Action"),
    //   Cell: ({ row }) => (
    //     <div className="action border-bottom-0">
    //       <Link
    //         to="#saveInvoice"
    //         className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
    //         data-bs-toggle="offcanvas"
    //         role="button"
    //         aria-controls="saveInvoice"
    //         onClick={() => {
    //           setSelectedInvoiceId(row.original._id);
    //         }}
    //       >
    //         <span className="d-block">Details</span>
    //         <span className="d-block material-symbols-outlined">
    //           chevron_right
    //         </span>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  //* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const resetFilterData = () => {
    setfilterStartDate("");
    setfilterEndDate("");
    setfilterStartingPrice("");
    setfilterEndingPrice("");
    setfilterTags([]);
    setfilterPromisePercent("");
    setfilterTitle("");
    setfilterOwners([]);
    setfilterFollowers([]);

    setLastPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });

    setReloadData(true);
  };

  useEffect(() => {
    if (
      filterStartDate !== "" ||
      filterEndDate !== "" ||
      filterStartingPrice !== "" ||
      filterEndingPrice !== "" ||
      filterOwners.length > 0 ||
      filterFollowers.length > 0 ||
      filterTags.length > 0 ||
      filterPromisePercent !== "" ||
      filterTitle !== ""
    ) {
      getInvoiceList();
    }
  }, [
    filterStartDate,
    filterEndDate,
    filterStartingPrice,
    filterEndingPrice,
    filterOwners,
    filterFollowers,
    filterTags,
    filterPromisePercent,
    filterTitle,
  ]);

  useEffect(() => {
    if (isNoDefaultFilter) {
      getInvoiceList();
    }
  }, [isNoDefaultFilter]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedInvoiceIds(selectedIdsArray);
    } else {
      setSelectedInvoiceIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (pagination.pageIndex > 0 || pagination.pageSize > 10) {
      setLastPagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getInvoiceList();
      setReloadData(false);
    }
  }, [reloadData]);

  /**
   * Closes the alert by updating the state variables.
   *
   * @return {void} No return value.
   */
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") &&
      moduleAccess.includes("MOD_OPPORTUNITY"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Opportunities")}
          />
          <div className="container-fluid px-lg-5">
            <OpportunityHeader
              reloadList={resetFilterData}
              invoiceList={invoiceList}
              followingOpportunityCount={followingOpportunityCount}
              totalNotesCount={totalNotesCount}
            />
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns}
                  data={invoiceList}
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, pagination: lastPagination }}
                  onPaginationChange={setPagination} // set pagination
                  // defaultColumn={{
                  //   minSize: 20,
                  //   maxSize: 200,
                  //   size: 50, //make columns wider by default
                  // }}
                  enableRowActions
                  renderRowActionMenuItems={({ row }) => {
                    return [
                      <MenuItem key="details">
                        <Link
                          className="dropdown-item"
                          to={`/admin/crm/opportunity/save/${row.id}`}
                        >
                          {t("View and save Opportunity")}
                        </Link>
                      </MenuItem>,
                    ];
                  }} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        <AddLabelModal
          moduleName="invoice"
          selectedIds={selectedInvoiceIds}
          afterTagModalClose={() => {
            setTimeout(() => {
              getInvoiceList();
            }, 2500);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <AddNewCrmOpportunityPopup
          reloadList={() => {
            setTimeout(() => {
              getInvoiceList();
            }, 2500);
          }}
          moudleSlug={moduleSlug}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <SaveInvoicePopup
          leadId={null}
          contactPersonId={null}
          moudleSlug={moduleSlug}
          moduleName={""}
          selectedInvoiceId={selectedInvoiceId}
          setSelectedInvoiceId={setSelectedInvoiceId}
          reloadList={() => {
            setTimeout(() => {
              getInvoiceList();
            }, 2500);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <InvoiceListFIlterPopup
          componentName={"crmopportunity"}
          moduleType={"list"}
          isReset={isFilterReset}
          setIsReset={setIsFilterReset}
          filterStartDate={filterStartDate}
          setfilterStartDate={setfilterStartDate}
          filterEndDate={filterEndDate}
          setfilterEndDate={setfilterEndDate}
          filterStartingPrice={filterStartingPrice}
          setfilterStartingPrice={setfilterStartingPrice}
          filterEndingPrice={filterEndingPrice}
          setfilterEndingPrice={setfilterEndingPrice}
          filterTags={filterTags}
          setfilterTags={setfilterTags}
          filterOwners={filterOwners}
          setfilterOwners={setfilterOwners}
          filterFollowers={filterFollowers}
          setfilterFollowers={setfilterFollowers}
          filterPromisePercent={filterPromisePercent}
          setfilterPromisePercent={setfilterPromisePercent}
          filterTitle={filterTitle}
          setfilterTitle={setfilterTitle}
          setIsNoDefaultFilter={setIsNoDefaultFilter}
        />

        <AddFollowerModal
          moduleName="opportunity"
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          selectedIds={selectedInvoiceIds}
          afterModalClose={() => {
            setTimeout(() => {
              getInvoiceList();
            }, 2500);
          }}
        />
        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedInvoiceIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t("opportunity")}
        />

        <SaveDirectAccessModal
          moduleSlug="MOD_CRM_OPPORTUNITY"
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="empty_access text-center">
        <div className="empty_pic mb-4">
          {" "}
          <img src={assetImages.emptyVector} alt="" />
        </div>
        <div className="empty_text">
          <p className="fs-lg text-gray fw-semibold mb-4">
            {t("Sorry....! You don't have privilege to see this content")}
          </p>
        </div>
      </div>
    );
  }
};

export default CrmOpportunityBody;
