/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CarrierHighlightsModal = ({ userCarrierHighlights, setUserCareerHighlights }) => {
  const carrierHighlightsTemplate = {
    highlighttext: "",
  };

  const [carrierHighlightsBlock, setCarrierHighlightsBlock] = useState([]);

  const addNewCarrierHighlightsBlock = () => {
    setCarrierHighlightsBlock([...carrierHighlightsBlock, carrierHighlightsTemplate]);
  };

  const deleteCarrierHighlightsBlock = (index) => {
    const deleteCarrierHighlightsArr = [...carrierHighlightsBlock];
    deleteCarrierHighlightsArr.splice(index, 1);
    setCarrierHighlightsBlock(deleteCarrierHighlightsArr);
  };

  //function for change survey select
  const carrierHighlightsInputHandler = (index, field, value) => {
    const updatedFormValues = carrierHighlightsBlock.map((block, i) =>
      index == i? Object.assign(block, { [field]: value }) : block
    );

    setCarrierHighlightsBlock(updatedFormValues);
  };

  const updatedCarrierHighlightsBlock = (updatedData) => {
    setCarrierHighlightsBlock(updatedData);
  };

  const saveInfoHandler = () => {
    if (carrierHighlightsBlock.length > 0) {
      setUserCareerHighlights(carrierHighlightsBlock);
    } else {
      setUserCareerHighlights([]);
    }

    resetHandler();

    let loginModal = document.querySelector("#carrierHighlights_modal");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  const resetHandler = () => {
    setCarrierHighlightsBlock([]);
  };

  useEffect(() => {
    if (userCarrierHighlights.length > 0) {
      updatedCarrierHighlightsBlock(userCarrierHighlights);
    }
  }, [userCarrierHighlights]);

  return (
    <div className="process_modal builder_modal">
      

      <div
        className="modal fade"
        id="carrierHighlights_modal"
        tabIndex="-1"
        aria-labelledby="addDetailsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>Carrier Highlights</h3>
                <h5>Add the mail highlights, achievements of your carrier</h5>
              </div>
              <button
                type="button"
                className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_innr">
                  <div className="form-group">
                    {carrierHighlightsBlock.map((carrierHighlightsData, index) => {
                      return (
                        <div className="d-flex gap-1 mb-2" key={index}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Carrier Highlights"
                            value={carrierHighlightsData.highlighttext}
                            onChange={(e) => {
                              carrierHighlightsInputHandler(
                                index,
                                "highlighttext",
                                e.target.value
                              );
                            }}
                          />
                          <div className="adnew_btn">
                            <Link
                              onClick={() => {
                                deleteCarrierHighlightsBlock(index);
                              }}
                              to="#"
                              className="d-inline-flex align-items-center justify-content-center gap-1"
                            >
                              <i className="material-symbols-outlined">delete</i>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="form-group">
                    <div className="adnew_btn">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center justify-content-center gap-1"
                        onClick={addNewCarrierHighlightsBlock}
                      >
                        <i className="material-symbols-outlined">add </i>
                        <span>Add New</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <button
                    onClick={resetHandler}
                    data-bs-dismiss="modal"
                    className="btn gray"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary" onClick={saveInfoHandler}>
                    Add to CV
                  </button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CarrierHighlightsModal;
