/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const OtherSkillsModal = ({ userOtherSkills, setuserOtherSkills }) => {
  const otherSkillsTemplate = {
    skilltext: "",
  };

  const [otherSkillsBlock, setOtherSkillsBlock] = useState([]);

  const addNewOtherSkillsBlock = () => {
    setOtherSkillsBlock([...otherSkillsBlock, otherSkillsTemplate]);
  };

  const deleteOtherSkillsBlock = (index) => {
    const deleteOtherSkillsArr = [...otherSkillsBlock];
    deleteOtherSkillsArr.splice(index, 1);
    setOtherSkillsBlock(deleteOtherSkillsArr);
  };

  //function for change survey select
  const otherSkillsInputHandler = (index, field, value) => {
    const updatedFormValues = otherSkillsBlock.map((block, i) =>
      index == i? Object.assign(block, { [field]: value }) : block
    );

    setOtherSkillsBlock(updatedFormValues);
  };

  const updatedOtherSkillsBlock = (updatedData) => {
    setOtherSkillsBlock(updatedData);
  };

  const saveInfoHandler = () => {
    if (otherSkillsBlock.length > 0) {
      setuserOtherSkills(otherSkillsBlock);
    } else {
      setuserOtherSkills([]);
    }

    resetHandler();

    let loginModal = document.querySelector("#otherSkills_modal");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  const resetHandler = () => {
    setOtherSkillsBlock([]);
  };

  useEffect(() => {
    if (userOtherSkills.length > 0) {
      updatedOtherSkillsBlock(userOtherSkills);
    }
  }, [userOtherSkills]);

  return (
    <div className="process_modal builder_modal">
      

      <div
        className="modal fade"
        id="otherSkills_modal"
        tabIndex="-1"
        aria-labelledby="addDetailsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-left">
                <h3>Soft Skills</h3>
                <h5>My Other Skills</h5>
              </div>
              <button
                type="button"
                className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_innr">
                  <div className="form-group">
                    {otherSkillsBlock.map((otherSkillsData, index) => {
                      return (
                        <div className="d-flex gap-1 mb-2" key={index}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Other Skills"
                            value={otherSkillsData.skilltext}
                            onChange={(e) => {
                              otherSkillsInputHandler(
                                index,
                                "skilltext",
                                e.target.value
                              );
                            }}
                          />
                          <div className="adnew_btn">
                            <Link
                              onClick={() => {
                                deleteOtherSkillsBlock(index);
                              }}
                              to="#"
                              className="d-inline-flex align-items-center justify-content-center gap-1"
                            >
                              <i className="material-symbols-outlined">delete</i>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="form-group">
                    <div className="adnew_btn">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center justify-content-center gap-1"
                        onClick={addNewOtherSkillsBlock}
                      >
                        <i className="material-symbols-outlined">add </i>
                        <span>Add New</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <button
                    onClick={resetHandler}
                    data-bs-dismiss="modal"
                    className="btn gray"
                  >
                    Cancel
                  </button>
                  <button className="btn" onClick={saveInfoHandler}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherSkillsModal;
