/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

const DirectAccessHeader = ({ reloadList = () => {} }) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("Direct Access") }];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-6 col-lg-5 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbText} />
        </div>
        <div className="col-md-6 col-lg-7 d-flex justify-content-end gap-1 gap-sm-2">
          {/* <!-- ========== Start refresh Section ========== --> */}
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          {/* <!-- ========== End refresh Section ========== --> */}

          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCRMDirectAccessFilter"
            aria-controls="offcanvasCRMDirectAccessFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DirectAccessHeader;
