/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import * as url from "helper/UrlHelper";
import { assetImages } from "constants";

const DashboardBody = () => {
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const date = new Date();

  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  const formattedDate = date.toLocaleDateString("es-ES", options);
  const dayOfWeek = date.toLocaleDateString("es-ES", { weekday: "long" });

  return (
    <div id="content_wrapper">
      <section className="hr-dashboard-wrapper pt-2 pt-lg-0 pb-5">
        <div className="dashboard-header bg-white">
          <div className="inner-menu-container shadow-sm mb-1"></div>
          <div className="py-4 shadow-sm">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-md-6 mb-4 mb-md-0">
                  <div className="profile d-flex align-items-center gap-3 mb-2">
                    <div
                      className="avatar rounded-circle overflow-hidden"
                      style={{ width: "2.5rem", height: "2.5rem" }}
                    >
                      <img
                        src={
                          userInfo.photoimage
                            ? url.SERVER_URL + userInfo.photoimage.path
                            : assetImages.defaultUser
                        }
                        alt="Peter Sjorgen"
                        className="w-100 h-100 object-center object-fit-cover"
                      />
                    </div>
                    <h1 className="h2 mb-0">
                      Hello,{" "}
                      <span>
                        {userInfo.name} {userInfo.surname}
                      </span>
                    </h1>
                  </div>
                  <p className="fs-sm text-gray">It's {formattedDate}</p>
                </div>
                <div className="col-md-6">
                  <div className="d-flex gap-2">
                    <a
                      href="javascript: void(0);"
                      className="body-bg d-flex align-items-center gap-1 px-3 py-2 fs-sm fw-semibold text-black rounded-10 ms-auto"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        event_available
                      </span>
                      <span className="d-block">My Schedules</span>
                    </a>
                    <a
                      href="expert-profile-info.html"
                      className="btn btn-primary d-flex align-items-center gap-1"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        timer
                      </span>
                      <span className="d-block">Start Working</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-content pt-4">
          <div className="container-fluid px-lg-5">
            <div className="row mb-4">
              <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                <div className="d-flex p-4 bg-white rounded-10 shadow-sm">
                  <div className="flex-fill">
                    <p className="fw-semibold">Contacts & Connects</p>
                    <h3 className="fw-bold d-flex gap-1 align-items-end mb-0 mt-3 mb-2">
                      <span className="d-block h2 mb-0">40</span>
                      <span className="d-block h3 text-gray mb-0">/400</span>
                    </h3>
                    <p className="d-flex align-items-center gap-1 fs-xs text-gray">
                      <span className="d-block material-symbols-outlined icon-md text-primary">
                        trending_down
                      </span>
                      <span className="d-block text-primary">10%</span>
                      <span className="d-block">absent for today</span>
                    </p>
                  </div>
                  <div
                    className="icon flex-shrink-0 d-flex align-items-center justify-content-center"
                    style={{
                      width: "3.75rem",
                      height: "3.75rem",
                      borderRadius: "1.125rem",
                      backgroundColor: "rgb(130 128 255 / 21%)",
                      color: "#8280FF",
                    }}
                  >
                    <svg
                      width="32"
                      height="24"
                      viewBox="0 0 32 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.375 13.0625C3.03159 13.0625 1.125 11.1564 1.125 8.8125C1.125 6.46857 3.03159 4.5625 5.375 4.5625C7.71841 4.5625 9.625 6.46857 9.625 8.8125C9.625 11.1564 7.71841 13.0625 5.375 13.0625ZM7.25565 13.7743C6.04425 14.2422 4.70575 14.2422 3.49435 13.7743C3.18619 13.657 2.83548 13.6996 2.55948 13.8698C0.997375 14.8586 0.0625 16.5364 0.0625 18.375V22.625C0.0625 23.2092 0.540833 23.6875 1.125 23.6875H6.4375V18.375C6.4375 16.7283 6.87329 15.1242 7.65927 13.7214C7.52127 13.6996 7.38327 13.7214 7.25565 13.7743ZM26.625 13.0625C28.9684 13.0625 30.875 11.1564 30.875 8.8125C30.875 6.46857 28.9684 4.5625 26.625 4.5625C24.2816 4.5625 22.375 6.46857 22.375 8.8125C22.375 11.1564 24.2816 13.0625 26.625 13.0625ZM29.4405 13.8698C29.1645 13.6996 28.8138 13.657 28.5056 13.7743C27.2943 14.2422 25.9557 14.2422 24.7444 13.7743C24.6167 13.7214 24.4787 13.6996 24.3407 13.7214C25.1267 15.1242 25.5625 16.7283 25.5625 18.375V23.6875H30.875C31.4592 23.6875 31.9375 23.2092 31.9375 22.625V18.375C31.9375 16.5364 31.0026 14.8586 29.4405 13.8698ZM16 10.9375C13.0709 10.9375 10.6875 8.55414 10.6875 5.625C10.6875 2.69586 13.0709 0.3125 16 0.3125C18.9291 0.3125 21.3125 2.69586 21.3125 5.625C21.3125 8.55414 18.9291 10.9375 16 10.9375ZM23.4375 18.375C23.4375 16.0051 22.3008 13.7743 20.388 12.3829C20.0695 12.1484 19.6446 12.1172 19.2939 12.2864C18.5821 12.6485 17.8277 12.8716 17.0625 12.9774V18.375C17.0625 18.9592 16.5842 19.4375 16 19.4375C15.4158 19.4375 14.9375 18.9592 14.9375 18.375V12.9774C14.1723 12.8716 13.4179 12.6485 12.7061 12.2864C12.3554 12.1172 11.9305 12.1484 11.612 12.3829C9.69919 13.7743 8.5625 16.0051 8.5625 18.375V23.6875H23.4375V18.375Z"
                        fill="#8A87E8"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                <div className="d-flex p-4 bg-white rounded-10 shadow-sm">
                  <div className="flex-fill">
                    <p className="fw-semibold">Leads</p>
                    <h3 className="fw-bold d-flex gap-1 align-items-end mb-0 mt-3 mb-2">
                      <span className="d-block h2 mb-0">1220</span>
                    </h3>
                    <p className="d-flex align-items-center gap-1 fs-xs text-gray">
                      <span className="d-block material-symbols-outlined icon-md text-success">
                        trending_up
                      </span>
                      <span className="d-block text-success">06%</span>
                      <span className="d-block">Increase from Last Month</span>
                    </p>
                  </div>
                  <div
                    className="icon flex-shrink-0 d-flex align-items-center justify-content-center"
                    style={{
                      width: "3.75rem",
                      height: "3.75rem",
                      borderRadius: "1.125rem",
                      backgroundColor: "rgb(254 197 61 / 21%)",
                      color: "#FEC53D",
                    }}
                  >
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.9194 31.0229H3.14755C1.34794 31.0229 0.0662988 29.2698 0.617471 27.5565L2.80224 20.73C3.50614 18.5386 5.90341 17.403 8.04169 18.2331C9.46278 18.7843 11.289 19.2159 13.5268 19.2159C15.5124 19.2159 17.1725 18.8772 18.5206 18.4124C18.7331 19.9132 19.4171 21.3675 20.5725 22.5163C21.894 23.8378 23.6405 24.5882 25.5065 24.6413L26.4429 27.5565C26.994 29.2698 25.719 31.0229 23.9194 31.0229Z"
                        fill="#FEC53D"
                      />
                      <path
                        d="M13.534 16.6695C17.3152 16.6695 20.3805 13.6043 20.3805 9.82305C20.3805 6.04184 17.3152 2.97656 13.534 2.97656C9.75278 2.97656 6.6875 6.04184 6.6875 9.82305C6.6875 13.6043 9.75278 16.6695 13.534 16.6695Z"
                        fill="#FEC53D"
                      />
                      <path
                        d="M33.1644 23.1589L30.6675 20.662C32.1948 18.3577 31.9425 15.2167 29.9171 13.1913C27.5995 10.8737 23.8343 10.8737 21.5233 13.1913C19.2058 15.5089 19.2058 19.2741 21.5233 21.5851C23.5554 23.6171 26.6964 23.8628 29.0007 22.3354L31.4976 24.8323C31.9558 25.2905 32.7062 25.2905 33.1644 24.8323C33.6226 24.3741 33.6226 23.6237 33.1644 23.1589ZM23.1835 19.9183C21.789 18.5237 21.789 16.2593 23.1835 14.8581C24.578 13.4569 26.8425 13.4636 28.2437 14.8581C29.6448 16.2526 29.6382 18.5171 28.2437 19.9116C26.8491 21.3128 24.5847 21.3128 23.1835 19.9183Z"
                        fill="#FEC53D"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                <div className="d-flex p-4 bg-white rounded-10 shadow-sm">
                  <div className="flex-fill">
                    <p className="fw-semibold">Opportunity</p>
                    <h3 className="fw-bold d-flex gap-1 align-items-end mb-0 mt-3 mb-2">
                      <span className="d-block h2 mb-0">$131.6k</span>
                    </h3>
                    <p className="d-flex align-items-center gap-1 fs-xs text-gray">
                      <span className="d-block material-symbols-outlined icon-md text-success">
                        trending_up
                      </span>
                      <span className="d-block text-success">14%</span>
                      <span className="d-block"> Increase from Last Month</span>
                    </p>
                  </div>
                  <div
                    className="icon flex-shrink-0 d-flex align-items-center justify-content-center"
                    style={{
                      width: "3.75rem",
                      height: "3.75rem",
                      borderRadius: "1.125rem",
                      backgroundColor: "rgb(130 128 255 / 21%)",
                      color: "#4AD991",
                    }}
                  >
                    <svg
                      width="34"
                      height="30"
                      viewBox="0 0 34 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.0022 0.335938C12.1624 0.55634 8.56163 2.04842 5.6875 4.60991L10.1325 9.05488C11.8052 7.66227 13.8363 6.82071 16.0022 6.62282V0.335938Z"
                        fill="#4AD991"
                      />
                      <path
                        d="M8.72432 10.4605L4.27942 6.01562C1.71574 8.88896 0.221797 12.49 0 16.3307H6.28714C6.48636 14.1645 7.32972 12.1332 8.72432 10.4605Z"
                        fill="#4AD991"
                      />
                      <path
                        d="M27.7106 16.3307H33.9978C33.776 12.49 32.2821 8.88896 29.7183 6.01562L25.2734 10.4605C26.668 12.1331 27.5114 14.1645 27.7106 16.3307Z"
                        fill="#4AD991"
                      />
                      <path
                        d="M27.7116 18.3281C27.5719 19.8623 27.1341 21.142 26.6163 22.1684C27.1214 22.9474 27.5426 23.6058 27.8208 24.043C28.5785 25.2338 28.4108 26.7623 27.4128 27.7603C27.1705 28.0027 26.8937 28.1979 26.5938 28.3422L27.629 29.3775C27.8238 29.5723 28.0792 29.6697 28.3345 29.6697C28.5899 29.6697 28.8453 29.5723 29.04 29.3775C32.0233 26.3946 33.7628 22.5022 34.0008 18.3281H27.7116Z"
                        fill="#4AD991"
                      />
                      <path
                        d="M6.28934 18.3281H0C0.238066 22.5022 1.97758 26.3946 4.96075 29.3775C5.15558 29.5724 5.41091 29.6698 5.66625 29.6698C5.92158 29.6698 6.17698 29.5723 6.37181 29.3775L9.39409 26.355C9.7837 25.9654 9.7837 25.3336 9.39409 24.9439C8.60539 24.1552 6.61871 21.9471 6.28934 18.3281Z"
                        fill="#4AD991"
                      />
                      <path
                        d="M28.3108 4.60991C25.4366 2.04836 21.8358 0.55634 17.9961 0.335938V6.62282C20.1619 6.82071 22.193 7.66227 23.8658 9.05488L28.3108 4.60991Z"
                        fill="#4AD991"
                      />
                      <path
                        d="M19.2127 15.134C17.9924 13.9137 16.0067 13.9137 14.7864 15.134C13.566 16.3544 13.566 18.34 14.7864 19.5604C16.328 21.1021 23.9047 25.9398 24.7636 26.4863C25.1574 26.737 25.6725 26.6804 26.0027 26.3503C26.3328 26.0202 26.3894 25.5051 26.1387 25.1112C25.5921 24.2523 20.7544 16.6756 19.2127 15.134Z"
                        fill="#4AD991"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="d-flex p-4 bg-white rounded-10 shadow-sm">
                  <div className="flex-fill">
                    <p className="fw-semibold">Team members</p>
                    <h3 className="fw-bold d-flex gap-1 align-items-end mb-0 mt-3 mb-2">
                      <span className="d-block h2 mb-0">32</span>
                    </h3>
                    <p className="d-flex align-items-center gap-1 fs-xs text-gray">
                      <span className="d-block material-symbols-outlined icon-md text-primary">
                        trending_down
                      </span>
                      <span className="d-block text-primary">14%</span>
                      <span className="d-block">
                        {" "}
                        employees resigned from Last Month
                      </span>
                    </p>
                  </div>
                  <div
                    className="icon flex-shrink-0 d-flex align-items-center justify-content-center"
                    style={{
                      width: "3.75rem",
                      height: "3.75rem",
                      borderRadius: "1.125rem",
                      backgroundColor: "rgb(255 0 0 / 21%)",
                      color: "#FF0000",
                    }}
                  >
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6" clipPath="url(#clip0_7138_64551)">
                        <path
                          d="M21.2498 18.4164C20.4664 18.4164 19.8332 19.0511 19.8332 19.833V25.4997C19.8332 26.2803 19.1985 26.9163 18.4164 26.9163H14.1664V5.66652C14.1664 4.45669 13.3958 3.37579 12.237 2.97346L11.8176 2.83313H18.4164C19.1985 2.83313 19.8332 3.46917 19.8332 4.24996V8.49991C19.8332 9.28173 20.4664 9.91648 21.2498 9.91648C22.0332 9.91648 22.6663 9.28173 22.6663 8.49991V4.24996C22.6663 1.90683 20.7595 0 18.4164 0H3.18747C3.13351 0 3.08838 0.0241239 3.03598 0.0311276C2.96776 0.0254209 2.90265 0 2.83339 0C1.27078 0 0 1.27053 0 2.83313V28.3329C0 29.5427 0.770668 30.6236 1.92939 31.0259L10.455 33.8679C10.744 33.9571 11.0314 33.9996 11.3333 33.9996C12.8959 33.9996 14.1664 32.7289 14.1664 31.1663V29.7497H18.4164C20.7595 29.7497 22.6663 27.8429 22.6663 25.4997V19.833C22.6663 19.0511 22.0332 18.4164 21.2498 18.4164Z"
                          fill="#FF0000"
                        />
                        <path
                          d="M33.5862 13.1679L27.9194 7.5014C27.5145 7.09622 26.9052 6.97431 26.3755 7.19376C25.8471 7.41347 25.5013 7.93044 25.5013 8.50293V12.7529H19.8348C19.0527 12.7529 18.418 13.3874 18.418 14.1695C18.418 14.9515 19.0527 15.586 19.8348 15.586H25.5013V19.836C25.5013 20.4085 25.8471 20.9254 26.3755 21.1451C26.9052 21.3646 27.5145 21.2427 27.9194 20.8378L33.5862 15.171C34.14 14.6172 34.14 13.7217 33.5862 13.1679Z"
                          fill="#FF0000"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7138_64551">
                          <rect width="34" height="34" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="h-100 p-3 p-sm-4 bg-white rounded-10 shadow-sm">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h3 className="mb-0">Risk Prevention Compliance</h3>
                    <a
                      href="javascript: void(0);"
                      className="d-inline-block btn-link fw-bold text-primary text-underline"
                    >
                      View Details
                    </a>
                  </div>
                  <p className="fw-semibold mb-1">Health and Safety Training</p>
                  <p className="fs-xs lh-1 mb-2">36% employees</p>
                  <div
                    className="progress body-bg mb-4"
                    role="progressbar"
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ height: "0.75rem" }}
                  >
                    <div
                      className="progress-bar"
                      style={{ width: "40%" }}
                    ></div>
                  </div>
                  <div className="d-flex align-items-center gap-4">
                    <div
                      className="graph flex-shrink-0"
                      style={{ width: "10.625rem", height: "10.625rem" }}
                    >
                      <img
                        src="/assets/img/hr-dashboard-graph-1.png"
                        alt="Health and Safety Training"
                        className="w-100 h-100 object-center object-fit-cover"
                      />
                    </div>
                    <ul className="d-flex flex-fill flex-column gap-2 fs-sm">
                      <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#EE523C",
                          }}
                        ></span>
                        <span className="d-block">Safety Equipment</span>
                        <span className="d-block fw-semibold ms-auto">
                          (18%)
                        </span>
                      </li>
                      <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#757B7F",
                          }}
                        ></span>
                        <span className="d-block">Incident Reporting</span>
                        <span className="d-block fw-semibold ms-auto">
                          (18%)
                        </span>
                      </li>
                      <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#31A61E",
                          }}
                        ></span>
                        <span className="d-block">Risk Assessments</span>
                        <span className="d-block fw-semibold ms-auto">
                          (18%)
                        </span>
                      </li>
                      <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#B30000",
                          }}
                        ></span>
                        <span className="d-block">Compliance Audits</span>
                        <span className="d-block fw-semibold ms-auto">
                          (18%)
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="h-100 p-3 p-sm-4 bg-white rounded-10 shadow-sm">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3 className="mb-0">Absenteeism Management</h3>
                    <a
                      href="javascript: void(0);"
                      className="d-inline-block btn-link fw-bold text-primary text-underline"
                    >
                      View Details
                    </a>
                  </div>
                  <ul className="d-flex gap-2 fw-semibold text-center mb-4">
                    <li className=" flex-fill">
                      <span
                        className="d-block d-flex align-items-center justify-content-center text-white rounded-5 mb-2"
                        style={{
                          height: "3.438rem",
                          backgroundColor: "#8280FF",
                        }}
                      >
                        100%
                      </span>
                      <span className="d-block text-gray">Mon</span>
                    </li>
                    <li className=" flex-fill">
                      <span
                        className="d-block d-flex align-items-center justify-content-center text-gray rounded-5 mb-2"
                        style={{
                          height: "3.438rem",
                          backgroundColor: "rgb(130 128 255 / 20%)",
                        }}
                      >
                        23%
                      </span>
                      <span className="d-block text-gray">Tue</span>
                    </li>
                    <li className=" flex-fill">
                      <span
                        className="d-block d-flex align-items-center justify-content-center text-white rounded-5 mb-2"
                        style={{
                          height: "3.438rem",
                          backgroundColor: "rgb(130 128 255 / 56%)",
                        }}
                      >
                        50%
                      </span>
                      <span className="d-block text-gray">Wed</span>
                    </li>
                    <li className=" flex-fill">
                      <span
                        className="d-block d-flex align-items-center justify-content-center text-gray rounded-5 mb-2"
                        style={{
                          height: "3.438rem",
                          backgroundColor: "rgb(130 128 255 / 30%)",
                        }}
                      >
                        30%
                      </span>
                      <span className="d-block text-gray">Thu</span>
                    </li>
                    <li className=" flex-fill">
                      <span
                        className="d-block d-flex align-items-center justify-content-center text-gray rounded-5 mb-2"
                        style={{
                          height: "3.438rem",
                          backgroundColor: "rgb(130 128 255 / 16%)",
                        }}
                      >
                        14%
                      </span>
                      <span className="d-block">Fri</span>
                    </li>
                  </ul>
                  <ul className="d-flex flex-column">
                    <li className="pb-3 border-bottom border-gray-300 mb-3">
                      <p className="fw-semibold mb-2">Absent</p>
                      <div className="d-flex gap-3 justify-content-between">
                        <ul className="d-flex avatar-collage">
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/rockdisket.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/jhondisusa.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/richardgomes.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/rockdisket.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/jhondisusa.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/richardgomes.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li className="fs-xs lh-1 ms-2">
                            <span className="d-block fw-semibold mb-1">
                              40+
                            </span>
                            <span className="d-block text-gray">Today</span>
                          </li>
                        </ul>
                        <a
                          href="javascript: void(0);"
                          className="bg-primary fs-sm lh-1 fw-semibold text-white px-2 py-1 d-flex align-items-center gap-2 rounded-90"
                        >
                          <span className="d-block material-symbols-outlined icon-md icon-fill">
                            cancel_presentation
                          </span>
                          <span className="d-block">Absent</span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <p className="fw-semibold mb-2">Absence Approvals</p>
                      <div className="d-flex gap-3 justify-content-between">
                        <ul className="d-flex avatar-collage">
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/rockdisket.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/jhondisusa.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/richardgomes.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/rockdisket.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li className="fs-xs lh-1 ms-2">
                            <span className="d-block fw-semibold mb-1">
                              14+
                            </span>
                            <span className="d-block text-gray">This week</span>
                          </li>
                        </ul>
                        <a
                          href="javascript: void(0);"
                          className="fs-sm lh-1 fw-semibold text-white px-2 py-1 d-flex align-items-center gap-2 rounded-90"
                          style={{
                            background:
                              "linear-gradient(90deg, #FF6C65 0.1%, #FF9900 100%)",
                          }}
                        >
                          <span className="d-block material-symbols-outlined icon-md icon-fill">
                            schedule
                          </span>
                          <span className="d-block">Pending</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="h-100 p-3 p-sm-4 bg-white rounded-10 shadow-sm">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3 className="mb-0">Performance Evaluation</h3>
                    <a
                      href="javascript: void(0);"
                      className="d-inline-block btn-link fw-bold text-primary text-underline"
                    >
                      View Reports
                    </a>
                  </div>
                  <p className="fw-semibold mb-1">Total employer and staff</p>
                  <p className="d-flex align-items-center gap-1 fs-xs text-gray mb-3">
                    <span className="d-block material-symbols-outlined icon-md text-success">
                      trending_up
                    </span>
                    <span className="d-block text-success">14%</span>
                    <span className="d-block"> Increase from Last Month</span>
                  </p>
                  <img
                    src="/assets/img/hr-dashboard-graph-2.png"
                    alt=""
                    className="img-fluid mb-4"
                  />
                  <p className="fw-semibold mb-2">Performance by Group</p>
                  <ul className="d-flex flex-fill flex-column gap-2 fs-sm">
                    <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                      <span
                        className="d-block rounded-circle"
                        style={{
                          width: "0.5rem",
                          height: "0.5rem",
                          backgroundColor: "#21960F",
                        }}
                      ></span>
                      <span className="d-block">Staff</span>
                      <p className="d-flex align-items-center gap-1 ms-auto">
                        <span className="d-block fw-semibold">(06%)</span>
                        <span className="d-block material-symbols-outlined icon-md text-success">
                          trending_up
                        </span>
                      </p>
                    </li>
                    <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                      <span
                        className="d-block rounded-circle"
                        style={{
                          width: "0.5rem",
                          height: "0.5rem",
                          backgroundColor: "#FF6C65",
                        }}
                      ></span>
                      <span className="d-block">Internship and Contract</span>
                      <p className="d-flex align-items-center gap-1 ms-auto">
                        <span className="d-block fw-semibold">(04%)</span>
                        <span className="d-block material-symbols-outlined icon-md text-success">
                          trending_up
                        </span>
                      </p>
                    </li>
                    <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                      <span
                        className="d-block rounded-circle"
                        style={{
                          width: "0.5rem",
                          height: "0.5rem",
                          backgroundColor: "#EE523C",
                        }}
                      ></span>
                      <span className="d-block">CEO and Director</span>
                      <p className="d-flex align-items-center gap-1 ms-auto">
                        <span className="d-block fw-semibold">(04%)</span>
                        <span className="d-block material-symbols-outlined icon-md text-success">
                          trending_up
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-8 mb-4 mb-lg-0">
                <div className="h-100 p-3 p-sm-4 bg-white rounded-10 shadow-sm">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3 className="mb-0">Onboarding</h3>
                    <a href="javascript: void(0);" className="btn btn-gray">
                      <span className="d-block material-symbols-outlined icon-md">
                        tune
                      </span>
                    </a>
                  </div>
                  <div className="table-wrapper overflow-y-auto">
                    <div className="table-responsive">
                      <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                        <thead>
                          <tr>
                            <th className="employees-id bg-transparent fs-md fw-normal border-0">
                              Employees ID
                            </th>
                            <th className="employee  bg-transparent fs-md fw-normal border-0">
                              Employee
                            </th>
                            <th className="email bg-transparent fs-md fw-normal border-0">
                              Email
                            </th>
                            <th className="role bg-transparent fs-md fw-normal border-0">
                              Role
                            </th>
                            <th className="phase bg-transparent fs-md fw-normal border-0">
                              Phase
                            </th>
                            <th className="status bg-transparent fs-md fw-normal border-0">
                              Status
                            </th>
                            <th className="action bg-transparent fs-md fw-normal border-0">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="employees-id border-bottom-0">
                              <p className="fw-semibold">8056-5698</p>
                            </td>
                            <td className="employees border-bottom-0">
                              <div className="d-flex align-items-center gap-2">
                                <div
                                  className="avatar rounded-circle overflow-hidden"
                                  style={{
                                    width: "22px",
                                    height: "22px",
                                  }}
                                >
                                  <img
                                    src="/assets/img/dp-1.jpg"
                                    alt="Hazel Nutt"
                                    className="w-100 h-100 object-fit-cover object-center"
                                  />
                                </div>
                                <p>Hazel Nutt</p>
                              </div>
                            </td>
                            <td className="email border-bottom-0">
                              <a href="mailto:hazelnutt@mail.com">
                                hazelnutt@mail.com
                              </a>
                            </td>
                            <td className="role border-bottom-0">
                              <p>Lead UI/UX</p>
                            </td>
                            <td className="phase border-bottom-0">
                              <p>Training</p>
                            </td>
                            <td className="status border-bottom-0">
                              <span
                                className="d-inline-block px-3 py-1 fs-xs text-white rounded-90"
                                style={{
                                  background:
                                    "linear-gradient(90deg, #FF6C65 0.1%, #FF9900 100%)",
                                }}
                              >
                                In Progress
                              </span>
                            </td>
                            <td className="action border-bottom-0">
                              <a
                                href="#offcanvasUpdateList"
                                className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                                data-bs-toggle="offcanvas"
                                role="button"
                                aria-controls="offcanvasUpdateList"
                              >
                                <span className="d-block">Details</span>
                                <span className="d-block material-symbols-outlined">
                                  chevron_right
                                </span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="employees-id border-bottom-0">
                              <p className="fw-semibold">8056-5698</p>
                            </td>
                            <td className="employees border-bottom-0">
                              <div className="d-flex align-items-center gap-2">
                                <div
                                  className="avatar rounded-circle overflow-hidden"
                                  style={{ width: "22px", height: "22px" }}
                                >
                                  <img
                                    src="/assets/img/dp-1.jpg"
                                    alt="Hazel Nutt"
                                    className="w-100 h-100 object-fit-cover object-center"
                                  />
                                </div>
                                <p>Hazel Nutt</p>
                              </div>
                            </td>
                            <td className="email border-bottom-0">
                              <a href="mailto:hazelnutt@mail.com">
                                hazelnutt@mail.com
                              </a>
                            </td>
                            <td className="role border-bottom-0">
                              <p>Lead UI/UX</p>
                            </td>
                            <td className="phase border-bottom-0">
                              <p>Training</p>
                            </td>
                            <td className="status border-bottom-0">
                              <span
                                className="d-inline-block px-3 py-1 fs-xs text-white rounded-90"
                                style={{
                                  background:
                                    "linear-gradient(90deg, #FF6C65 0.1%, #FF9900 100%)",
                                }}
                              >
                                In Progress
                              </span>
                            </td>
                            <td className="action border-bottom-0">
                              <a
                                href="#offcanvasUpdateList"
                                className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                                data-bs-toggle="offcanvas"
                                role="button"
                                aria-controls="offcanvasUpdateList"
                              >
                                <span className="d-block">Details</span>
                                <span className="d-block material-symbols-outlined">
                                  chevron_right
                                </span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="employees-id border-bottom-0">
                              <p className="fw-semibold">8056-5698</p>
                            </td>
                            <td className="employees border-bottom-0">
                              <div className="d-flex align-items-center gap-2">
                                <div
                                  className="avatar rounded-circle overflow-hidden"
                                  style={{ width: "22px", height: "22px" }}
                                >
                                  <img
                                    src="/assets/img/dp-1.jpg"
                                    alt="Hazel Nutt"
                                    className="w-100 h-100 object-fit-cover object-center"
                                  />
                                </div>
                                <p>Hazel Nutt</p>
                              </div>
                            </td>
                            <td className="email border-bottom-0">
                              <a href="mailto:hazelnutt@mail.com">
                                hazelnutt@mail.com
                              </a>
                            </td>
                            <td className="role border-bottom-0">
                              <p>Lead UI/UX</p>
                            </td>
                            <td className="phase border-bottom-0">
                              <p>Training</p>
                            </td>
                            <td className="status border-bottom-0">
                              <span
                                className="d-inline-block px-3 py-1 fs-xs text-white rounded-90"
                                sstyle={{
                                  background:
                                    "linear-gradient(90deg, #FF6C65 0.1%, #FF9900 100%)",
                                }}
                              >
                                In Progress
                              </span>
                            </td>
                            <td className="action border-bottom-0">
                              <a
                                href="#offcanvasUpdateList"
                                className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                                data-bs-toggle="offcanvas"
                                role="button"
                                aria-controls="offcanvasUpdateList"
                              >
                                <span className="d-block">Details</span>
                                <span className="d-block material-symbols-outlined">
                                  chevron_right
                                </span>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex flex-column h-100 p-3 p-sm-4 bg-white rounded-10 shadow-sm">
                  <div className="d-flex flex-shrink-0 align-items-center justify-content-between mb-3">
                    <h3 className="mb-0">Recruitment and Selection</h3>
                    <a
                      href="javascript: void(0);"
                      className="d-inline-block btn-link fw-bold text-primary text-underline"
                    >
                      View Details
                    </a>
                  </div>
                  <div className="d-flex flex-fill align-items-center gap-4">
                    <div
                      className="graph flex-shrink-0"
                      style={{ width: "10.625rem", height: "10.625rem" }}
                    >
                      <img
                        src="/assets/img/hr-dashboard-graph-3.png"
                        alt="Health and Safety Training"
                        className="w-100 h-100 object-center object-fit-cover"
                      />
                    </div>
                    <ul className="d-flex flex-fill flex-column gap-2 fs-sm">
                      <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#21960F",
                          }}
                        ></span>
                        <span className="d-block">Active Jobs</span>
                        <span className="d-block fw-semibold ms-auto">41</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#FF6C65",
                          }}
                        ></span>
                        <span className="d-block">In reviews Jobs</span>
                        <span className="d-block fw-semibold ms-auto">23</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#0000000F",
                          }}
                        ></span>
                        <span className="d-block">Finish Jobs</span>
                        <span className="d-block fw-semibold ms-auto">14</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="h-100 p-3 p-sm-4 bg-white rounded-10 shadow-sm">
                  <h3 className="mb-0">Training and Development</h3>
                  <h3 className="fw-bold d-flex gap-1 align-items-center mb-0 mt-3 mb-2">
                    <span className="d-block h2 mb-0">68%</span>
                    <span className="d-block material-symbols-outlined text-success">
                      arrow_upward
                    </span>
                  </h3>
                  <p className="fs-sm mb-2">Employee Completed Training</p>
                  <div
                    className="progress body-bg mb-3"
                    role="progressbar"
                    aria-label="Example 1px high"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ height: "12px" }}
                  >
                    <div
                      className="progress-bar"
                      style={{ width: "25%" }}
                    ></div>
                  </div>
                  <p className="fs-sm mb-2">Ongoing Training Programs</p>
                  <ul className="d-flex flex-column gap-2 overflow-y-auto">
                    <li className="body-bg p-3 rounded-8">
                      <p className="fs-sm fw-semibold mb-1">
                        Design system training for ui design staff
                      </p>
                      <p className="fs-xs text-gray lh-1 mb-2">
                        We are currently in stealth mode and are backed
                      </p>
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <ul className="d-flex avatar-collage">
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/rockdisket.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/jhondisusa.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/richardgomes.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/rockdisket.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/jhondisusa.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/richardgomes.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li className="fs-xs lh-1 ms-2">
                            <span className="d-block fw-semibold mb-1">
                              40+
                            </span>
                            <span className="d-block text-gray">Employees</span>
                          </li>
                        </ul>
                        <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90">
                          <span className="d-block material-symbols-outlined icon-md">
                            event_available
                          </span>
                          <span className="d-block">08-02-2024</span>
                        </p>
                      </div>
                    </li>
                    <li className="body-bg p-3 rounded-8">
                      <p className="fs-sm fw-semibold mb-1">
                        Design system training for ui design staff
                      </p>
                      <p className="fs-xs text-gray lh-1 mb-2">
                        We are currently in stealth mode and are backed
                      </p>
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <ul className="d-flex avatar-collage">
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/rockdisket.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/jhondisusa.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/richardgomes.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/rockdisket.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/jhondisusa.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li
                            className="rounded-circle border border-white"
                            style={{ width: "1.75rem", height: "1.75rem" }}
                          >
                            <img
                              src="/assets/img/richardgomes.png"
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                          <li className="fs-xs lh-1 ms-2">
                            <span className="d-block fw-semibold mb-1">
                              40+
                            </span>
                            <span className="d-block text-gray">Employees</span>
                          </li>
                        </ul>
                        <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90">
                          <span className="d-block material-symbols-outlined icon-md">
                            event_available
                          </span>
                          <span className="d-block">08-02-2024</span>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="d-flex flex-column h-100 p-3 p-sm-4 bg-white rounded-10 shadow-sm">
                  <div className="d-flex flex-shrink-0 align-items-center justify-content-between mb-3">
                    <h3 className="mb-0">Offboarding Management</h3>
                    <a
                      href="javascript: void(0);"
                      className="d-inline-block btn-link fw-bold text-primary text-underline"
                    >
                      View Offboarding
                    </a>
                  </div>
                  <div className="d-flex flex-column justify-content-center flex-fill">
                    <div className="graph mb-4">
                      <img
                        src="/assets/img/hr-dashboard-graph-4.png"
                        alt="Offboarding Management"
                        className="img-fluid d-block mx-auto"
                      />
                    </div>
                    <ul className="d-flex flex-wrap gap-2 fs-sm">
                      <li className="d-flex align-items-center gap-1">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#21960F",
                          }}
                        ></span>
                        <span className="d-block" style={{ color: "#21960F" }}>
                          14+
                        </span>
                        <span className="d-block fw-semibold">
                          Serving notice period
                        </span>
                      </li>
                      <li className="d-flex align-items-center gap-1">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#EE523C",
                          }}
                        ></span>
                        <span className="d-block" style={{ color: "#EE523C" }}>
                          05
                        </span>
                        <span className="d-block fw-semibold">
                          Scheduled exit interviews
                        </span>
                      </li>
                      <li className="d-flex align-items-center gap-1">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#4285F4",
                          }}
                        ></span>
                        <span className="d-block" style={{ color: "#4285F4" }}>
                          420+
                        </span>
                        <span className="d-block fw-semibold">
                          Property return remain
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="h-100 p-3 p-sm-4 bg-white rounded-10 shadow-sm">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3 className="mb-0">Time and Attendance Management</h3>
                    <a
                      href="javascript: void(0);"
                      className="d-inline-block btn-link fw-bold text-primary text-underline"
                    >
                      View Details
                    </a>
                  </div>
                  <p className="fs-sm fw-semibold mb-2">Absent</p>
                  <div className="d-flex gap-2 justify-content-between pb-3 border-bottom border-gray-300 mb-3">
                    <ul className="d-flex avatar-collage">
                      <li
                        className="rounded-circle border border-white"
                        style={{ width: "2.375rem", height: "2.375rem" }}
                      >
                        <img
                          src="/assets/img/rockdisket.png"
                          alt=""
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </li>
                      <li
                        className="rounded-circle border border-white"
                        style={{ width: "2.375rem", height: "2.375rem" }}
                      >
                        <img
                          src="/assets/img/jhondisusa.png"
                          alt=""
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </li>
                      <li
                        className="rounded-circle border border-white"
                        style={{ width: "2.375rem", height: "2.375rem" }}
                      >
                        <img
                          src="/assets/img/richardgomes.png"
                          alt=""
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </li>
                      <li
                        className="rounded-circle border border-white"
                        style={{ width: "2.375rem", height: "2.375rem" }}
                      >
                        <img
                          src="/assets/img/rockdisket.png"
                          alt=""
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </li>
                      <li
                        className="rounded-circle border border-white"
                        style={{ width: "2.375rem", height: "2.375rem" }}
                      >
                        <img
                          src="/assets/img/jhondisusa.png"
                          alt=""
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </li>
                      <li
                        className="rounded-circle border border-white"
                        style={{ width: "2.375rem", height: "2.375rem" }}
                      >
                        <img
                          src="/assets/img/richardgomes.png"
                          alt=""
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </li>
                      <li className="fs-xs lh-1 ms-2">
                        <span className="d-block fw-semibold mb-1">40+</span>
                        <span className="d-block text-gray">Today</span>
                      </li>
                    </ul>
                    <a
                      href="javascript: void(0);"
                      className="bg-primary fs-sm lh-1 fw-semibold text-white px-3 py-1 d-flex align-items-center gap-2 rounded-90"
                    >
                      <span className="d-block material-symbols-outlined icon-md icon-fill">
                        cancel_presentation
                      </span>
                      <span className="d-block">Absent</span>
                    </a>
                  </div>
                  <p className="fs-sm fw-semibold mb-2">Away</p>
                  <ul className="d-flex flex-column gap-2 h-100 overflow-y-auto">
                    <li className="d-flex align-items-center gap-3">
                      <div
                        className="avatar rounded-circle"
                        style={{ width: "2.625rem", height: "2.625rem" }}
                      >
                        <img
                          src="/assets/img/richardgomes.png"
                          alt="Arthur Taylor"
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </div>
                      <div>
                        <p className="name lh-1 fw-semibold mb-1">
                          Arthur Taylor
                        </p>
                        <p className="fs-sm lh-1 text-gray">Design Lead</p>
                      </div>
                      <p className="fs-sm text-gray mx-auto mb-0">Away</p>
                      <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90 ms-auto">
                        <span className="d-block material-symbols-outlined icon-fill icon-md">
                          schedule
                        </span>
                        <span className="d-block">08 Hr</span>
                      </p>
                    </li>
                    <li className="d-flex align-items-center gap-3">
                      <div
                        className="avatar rounded-circle"
                        style={{ width: "2.625rem", height: "2.625rem" }}
                      >
                        <img
                          src="/assets/img/jhondisusa.png"
                          alt="Getar Beter jnr"
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </div>
                      <div>
                        <p className="name lh-1 fw-semibold mb-1">
                          Getar Beter jnr
                        </p>
                        <p className="fs-sm lh-1 text-gray">Design Lead</p>
                      </div>
                      <p className="fs-sm text-gray mx-auto mb-0">Away</p>
                      <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90 ms-auto">
                        <span className="d-block material-symbols-outlined icon-fill icon-md">
                          schedule
                        </span>
                        <span className="d-block">10 Hr</span>
                      </p>
                    </li>
                    <li className="d-flex align-items-center gap-3">
                      <div
                        className="avatar rounded-circle"
                        style={{ width: "2.625rem", height: "2.625rem" }}
                      >
                        <img
                          src="/assets/img/rockdisket.png"
                          alt="Arthur Taylor"
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </div>
                      <div>
                        <p className="name lh-1 fw-semibold mb-1">
                          Arthur Taylor
                        </p>
                        <p className="fs-sm lh-1 text-gray">Design Lead</p>
                      </div>
                      <p className="fs-sm text-gray mx-auto mb-0">Away</p>
                      <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90 ms-auto">
                        <span className="d-block material-symbols-outlined icon-fill icon-md">
                          schedule
                        </span>
                        <span className="d-block">06 Hr</span>
                      </p>
                    </li>
                    <li className="d-flex align-items-center gap-3">
                      <div
                        className="avatar rounded-circle"
                        style={{ width: "2.625rem", height: "2.625rem" }}
                      >
                        <img
                          src="/assets/img/jhondisusa.png"
                          alt="Getar Beter jnr"
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </div>
                      <div>
                        <p className="name lh-1 fw-semibold mb-1">
                          Getar Beter jnr
                        </p>
                        <p className="fs-sm lh-1 text-gray">Design Lead</p>
                      </div>
                      <p className="fs-sm text-gray mx-auto mb-0">Away</p>
                      <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90 ms-auto">
                        <span className="d-block material-symbols-outlined icon-fill icon-md">
                          schedule
                        </span>
                        <span className="d-block">10 Hr</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="d-flex flex-column h-100 p-3 p-sm-4 bg-white rounded-10 shadow-sm">
                  <div className="d-flex flex-shrink-0 align-items-center justify-content-between mb-4">
                    <h3 className="mb-0">Compensation & Benefits</h3>
                    <a
                      href="javascript: void(0);"
                      className="d-inline-block btn-link fw-bold text-primary text-underline"
                    >
                      View Details
                    </a>
                  </div>
                  <div className="d-flex flex-fill align-items-center gap-4">
                    <div
                      className="graph flex-shrink-0"
                      style={{ width: "10.625rem", height: "10.625rem" }}
                    >
                      <img
                        src="/assets/img/hr-dashboard-graph-6.png"
                        alt="Health and Safety Training"
                        className="w-100 h-100 object-center object-fit-cover"
                      />
                    </div>
                    <ul className="d-flex flex-fill flex-column gap-2 fs-sm">
                      <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#31A61E",
                          }}
                        ></span>
                        <span className="d-block">
                          Salary adjustments pending
                        </span>
                        <span className="d-block fw-semibold ms-auto">
                          $800.04k
                        </span>
                      </li>
                      <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                        <span
                          className="d-block rounded-circle"
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            backgroundColor: "#EE523C",
                          }}
                        ></span>
                        <span className="d-block">Bonus Distribution</span>
                        <span className="d-block fw-semibold ms-auto">
                          $140.04k
                        </span>
                      </li>
                      <li className="d-flex flex-column gap-2 body-bg rounded-8 px-3 py-2">
                        <p className="d-flex gap-2 align-items-center mb-0">
                          <span
                            className="d-block rounded-circle"
                            style={{
                              width: "0.5rem",
                              height: "0.5rem",
                              backgroundColor: "#757B7F",
                            }}
                          ></span>
                          <span className="d-block">Benefits</span>
                        </p>
                        <p className="d-flex align-items-center gap-2 justify-content-between mb-0">
                          <span className="d-block fs-xs text-gray">
                            Healthcare
                          </span>
                          <span className="d-block fw-semibold">$164.04k</span>
                        </p>
                        <p className="d-flex align-items-center gap-2 justify-content-between mb-0">
                          <span className="d-block fs-xs text-gray">
                            Retirement Plans
                          </span>
                          <span className="d-block fw-semibold">$296.48k</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="h-100 p-3 p-sm-4 bg-white rounded-10 shadow-sm">
                  <h3 className="mb-0">Analytics & Reporting</h3>
                  <h3 className="fw-bold d-flex gap-1 align-items-center mb-0 mt-3 mb-4">
                    <span className="d-block h2 mb-0">14:11</span>
                    <span className="d-flex text-success">
                      <span className="d-block">9%</span>
                      <span className="d-block material-symbols-outlined icon-md">
                        arrow_upward
                      </span>
                    </span>
                  </h3>
                  <div className="graph mb-3">
                    <img
                      src="/assets/img/hr-dashboard-graph-5.png"
                      alt="Analytics & Reporting"
                      className="d-block img-fluid mx-auto"
                    />
                  </div>
                  <ul className="d-flex flex-wrap gap-3 fs-sm fw-semibold">
                    <li className="d-flex align-items-center gap-2">
                      <span
                        className="d-block rounded-circle"
                        style={{
                          width: "0.5rem",
                          height: "0.5rem",
                          backgroundColor: "#21960F",
                        }}
                      ></span>
                      <span className="d-block">Employee turnover rate</span>
                    </li>
                    <li className="d-flex align-items-center gap-2">
                      <span
                        className="d-block rounded-circle"
                        style={{
                          width: "0.5rem",
                          height: "0.5rem",
                          backgroundColor: "#EE523C",
                        }}
                      ></span>
                      <span className="d-block">Performance impact</span>
                    </li>
                    <li className="d-flex align-items-center gap-2">
                      <span
                        className="d-block rounded-circle"
                        style={{
                          width: "0.5rem",
                          height: "0.5rem",
                          backgroundColor: "#FF6C65",
                        }}
                      ></span>
                      <span className="d-block">Absenteeism trends</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DashboardBody;
