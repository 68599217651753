/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

import TaskTable from "../TaskTable/TaskTable";
import { useTranslation } from "react-i18next";

const ProjectSectionCard = ({
  setDeleteType,
  sectionData,
  index,
  isProjectModerator,
  setSelectedSectionValue,
  setSelectedEditTaskId,
  setSelectedSectionId,

  selectedTaskIds,
  setselectedTaskIds,

  filterTaskDateRangeType,
  filterTaskFromDate,
  filterTaskToDate,
  filterTaskCustomIds,
  filterTaskTitle,
  filterTaskLabels,
  filterTaskStatus,
  filterTaskPriorities,
  filterTaskAssignedByUsers,
  filterTaskAssignedToUsers,
  // resetFilterData,

  openSectionId,
  setOpenSectionId,

  afterSavedTaskData = null,
  setAfterSavedTaskData = () => {},
}) => {
  const sectionGlowCount = [1, 2];
  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const [isSectionComponentLoading, setIsSectionComponentLoading] =
    useState(false);
  const [sectionDetailsData, setSectionDetailsData] = useState(null);
  const [childSectionList, setChildSectionList] = useState([]);

  //get all section with task
  const getAllSections = async () => {
    try {
      setIsSectionComponentLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PROJECT_SECTION +
        `?token=${token}&projectid=${params.id}&parentsectionid=${sectionData?._id}&userrolestring=${userInfo.role.slug}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      setIsSectionComponentLoading(false);
      console.log(response);

      setChildSectionList(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (sectionData) {
      getAllSections();
    }
  }, [sectionData]);

  return (
    <div className="accordion-item bg-transparent border-0">
      {/* <!-- ========== Start title and other display Section ========== --> */}
      <h3 className="accordion-header d-flex align-items-center gap-3 justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <button
            // className={`accordion-button w-auto bg-transparent p-0 shadow-none ${
            //   sectionData.parentsectionid
            //     ? ""
            //     : openSectionId
            //     ? openSectionId.toString() === sectionData._id.toString()
            //       ? ""
            //       : "collapsed"
            //     : "collapsed"
            // }`}

            className={`accordion-button w-auto bg-transparent p-0 shadow-none ${
              sectionData.parentsectionid ? "" : "collapsed"
            }`}
            // className={`accordion-button w-auto bg-transparent p-0 shadow-none`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#panelsStayOpen-collapse${sectionData._id}`}
            aria-expanded="true"
            aria-controls={`#panelsStayOpen-collapse${sectionData._id}`}
            onClick={() => {
              if (!sectionData.parentsectionid) {
                setOpenSectionId(sectionData._id);
              }
              // setOpenSectionId(sectionData._id);
            }}
          ></button>
          {sectionData.title}
        </div>

        <div className="action d-flex align-items-center gap-3">
          {/* <!-- ========== Start task count Section ========== --> */}
          <span className="percent-of-progress d-inline-block fs-xs lh-1 p-2 bg-gray-300 rounded-90 ms-auto">
            {sectionData.sectiontasklist.length} {t("Tasks")}
          </span>
          {/* <!-- ========== End task count Section ========== --> */}
          {/* <!-- ========== Start task percent Section ========== --> */}
          {sectionData.completionpercentage ? (
            sectionData.completionpercentage < 50 ? (
              <span className="percent-of-progress d-inline-block fs-xs lh-1 text-white p-2 bg-primary rounded-90">
                {sectionData.completionpercentage} % {t("Completed")}
              </span>
            ) : (
              <span className="percent-of-progress d-inline-block fs-xs lh-1 p-2 gradient-light rounded-90">
                {sectionData.completionpercentage} % {t("Completed")}
              </span>
            )
          ) : sectionData.sectiontasklist.length > 0 ? (
            <span className="percent-of-progress d-inline-block fs-xs lh-1 text-white p-2 bg-primary rounded-90">
              0 % {t("Completed")}
            </span>
          ) : null}{" "}
          {/* <!-- ========== End task percent Section ========== --> */}
          {/* <!-- ========== Start edit icon ========== --> */}
          {isProjectModerator && (
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#addSectionModal"
              className="text-gray"
              onClick={() => {
                setSelectedSectionId(sectionData._id);
              }}
            >
              <span className="d-block material-symbols-outlined icon-lg">
                edit
              </span>
            </Link>
          )}
          {/* <!-- ========== End edit icon ========== --> */}
          {/* <!-- ========== Start delete icon ========== --> */}
          {isProjectModerator && (
            <Link
              to="#"
              className="text-gray"
              data-bs-toggle="modal"
              data-bs-target="#projectSectionDeleteModal"
              onClick={() => {
                setSelectedSectionId(sectionData._id);
              }}
            >
              <span className="d-block material-symbols-outlined icon-lg">
                delete
              </span>
            </Link>
          )}
          {/* <!-- ========== End delete icon ========== --> */}
          {/* <!-- ========== Start add task button ========== --> */}
          {isProjectModerator && (
            <Link
              to="#"
              className="btn btn-outline-primary d-inline-flex align-items-center gap-1"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#addTask_view"
              aria-controls="addTask_view"
              onClick={() => {
                setSelectedSectionValue({
                  label: sectionData.title,
                  value: sectionData._id,
                  startdate: sectionData.startdate,
                  enddate: sectionData.enddate,
                });
                setSelectedEditTaskId(null);
              }}
            >
              <span className="d-block material-symbols-outlined icon-md">
                add
              </span>
              <span className="d-block">{t("Add Task")}</span>
            </Link>
          )}
          {/* <!-- ========== End add task button ========== --> */}
          {/* <!-- ========== Start add section button ========== --> */}
          {isProjectModerator && (
            <Link
              to="#"
              className="text-primary btn gradient-light d-inline-flex align-items-center gap-1"
              data-bs-toggle="modal"
              data-bs-target="#addSectionModal"
              onClick={() => {
                setSelectedSectionValue({
                  label: sectionData.title,
                  value: sectionData._id,
                  startdate: sectionData.startdate,
                  enddate: sectionData.enddate,
                });
              }}
            >
              <i className="d-block material-symbols-outlined icon-lg">add</i>
              <span>{t("Add Sub-Section")}</span>
            </Link>
          )}
          {/* <!-- ========== End add section button ========== --> */}
        </div>
      </h3>
      {/* <!-- ========== End title and other display Section ========== --> */}

      {/* <!-- ========== Start tasks and child Section list block ========== --> */}
      <div
        id={`panelsStayOpen-collapse${sectionData._id}`}
        className={`accordion-collapse collapse ${
          // sectionData.parentsectionid &&

          openSectionId &&
          openSectionId?.toString() === sectionData._id.toString()
            ? "show"
            : ""
        }`}

        // className={`accordion-collapse collapse show`}
      >
        <div className="accordion-body p-0 pt-3">
          {/* <!-- ========== Start task table component ========== --> */}
          <TaskTable
            setDeleteType={setDeleteType}
            sectionData={sectionData}
            isProjectModerator={isProjectModerator}
            setSelectedEditTaskId={setSelectedEditTaskId}
            setSelectedSectionValue={setSelectedSectionValue}
            //checkbox requirements
            selectedTaskIds={selectedTaskIds}
            setselectedTaskIds={setselectedTaskIds}
            //filter requirements
            filterTaskDateRangeType={filterTaskDateRangeType}
            filterTaskFromDate={filterTaskFromDate}
            filterTaskToDate={filterTaskToDate}
            filterTaskCustomIds={filterTaskCustomIds}
            filterTaskTitle={filterTaskTitle}
            filterTaskLabels={filterTaskLabels}
            filterTaskStatus={filterTaskStatus}
            filterTaskPriorities={filterTaskPriorities}
            filterTaskAssignedByUsers={filterTaskAssignedByUsers}
            filterTaskAssignedToUsers={filterTaskAssignedToUsers}
            // resetFilterData={resetFilterData}
            afterSavedTaskData={afterSavedTaskData}
            setAfterSavedTaskData={setAfterSavedTaskData}
          />
          {/* <!-- ========== End task table component ========== --> */}

          {/* <!-- ========== Start add task button Section ========== --> */}
          {/* {isProjectModerator ? (
            <Link
              to="#"
              className="text-primary d-inline-flex align-items-center gap-1 fw-bold"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#addTask_view"
              aria-controls="addTask_view"
              onClick={() => {
                setSelectedSectionValue({
                  label: sectionData.title,
                  value: sectionData._id,
                  startdate: sectionData.startdate,
                  enddate: sectionData.enddate,
                });
                setSelectedEditTaskId(null);
              }}
            >
              <span className="d-block material-symbols-outlined icon-md">
                add
              </span>
              <span className="d-block">Add Task in "{sectionData.title}"</span>
            </Link>
          ) : null} */}
          {/* <!-- ========== End add task button Section ========== --> */}

          {/* <!-- ========== Start child Section Block ========== --> */}
          <div className="sub_accourdian_sec mb-4">
            <div className="accordion" id="sub-accordionExample">
              {isSectionComponentLoading ? (
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  {sectionGlowCount.map((item, index) => {
                    return (
                      <div
                        className="accordion-item bg-transparent border-0"
                        key={index}
                      >
                        <h3 className="accordion-header d-flex align-items-center gap-3">
                          <button
                            className="accordion-button w-auto bg-transparent p-0 shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                          ></button>
                          <span
                            className="placeholder"
                            style={{ width: "5rem", height: "1.125rem" }}
                          ></span>
                          <span
                            className="placeholder rounded-90 ms-auto"
                            style={{ width: "5.875rem", height: "1.75rem" }}
                          ></span>
                          <div className="action d-flex align-items-center gap-3 ">
                            <span
                              className="placeholder rounded"
                              style={{ width: "20px", height: "1.5px" }}
                            ></span>

                            <span
                              className="placeholder rounded"
                              style={{ width: "20px", height: "1.5px" }}
                            ></span>
                          </div>
                        </h3>
                      </div>
                    );
                  })}
                </div>
              ) : childSectionList.length > 0 ? (
                childSectionList.map((childSectionData, index2) => {
                  return (
                    <ProjectSectionCard
                      setDeleteType={setDeleteType}
                      key={index2}
                      index={childSectionData._id}
                      sectionData={childSectionData}
                      //task and section update requirements
                      isProjectModerator={isProjectModerator}
                      setSelectedSectionValue={setSelectedSectionValue}
                      setSelectedEditTaskId={setSelectedEditTaskId}
                      setSelectedSectionId={setSelectedSectionId}
                      //checkbox requiremnts
                      selectedTaskIds={selectedTaskIds}
                      setselectedTaskIds={setselectedTaskIds}
                      //filter requirements
                      filterTaskDateRangeType={filterTaskDateRangeType}
                      filterTaskFromDate={filterTaskFromDate}
                      filterTaskToDate={filterTaskToDate}
                      filterTaskCustomIds={filterTaskCustomIds}
                      filterTaskTitle={filterTaskTitle}
                      filterTaskLabels={filterTaskLabels}
                      filterTaskStatus={filterTaskStatus}
                      filterTaskPriorities={filterTaskPriorities}
                      filterTaskAssignedByUsers={filterTaskAssignedByUsers}
                      filterTaskAssignedToUsers={filterTaskAssignedToUsers}
                      // resetFilterData={resetFilterData}

                      openSectionId={openSectionId}
                      setOpenSectionId={setOpenSectionId}
                    />
                  );
                })
              ) : null}
            </div>
          </div>
          {/* <!-- ========== End child Section Block ========== --> */}
        </div>
      </div>
      {/* <!-- ========== End tasks and child Section list block ========== --> */}
    </div>
  );
};

export default ProjectSectionCard;
