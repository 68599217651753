import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";
const CrmContactListHeader = ({ reloadList }) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: "CRM", link: "/admin/crm/contact/list" },
    { title: t("Contacts") },
  ];
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbText} />
        </div>
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addToList"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    list
                  </span>
                  <span className="d-block">{t("Add To List")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#createAlertModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    alarm
                  </span>
                  <span className="d-block">{t("Create alert")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNewMail"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    email
                  </span>
                  <span className="d-block">{t("Send Mail")}</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">Delete</span>
                </Link>
              </li> */}
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#saveDirectAccess"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    switch_access_2
                  </span>
                  <span className="d-block">{t("Add Direct Access")}</span>
                </Link>
              </li>
            </ul>
          </div>
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCrmContactFilter"
            aria-controls="offcanvasFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
          <Link
            to="#"
            className="btn btn-primary d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#addContactModal"
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">{t("Add New")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CrmContactListHeader;
