/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import { assetImages } from "constants";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

import { getDateFormatInSpanish } from "helper/Common/CommonHelper";
import { useTranslation } from "react-i18next";

const TaskTable = ({
  setDeleteType,
  sectionData,
  isProjectModerator,
  setSelectedEditTaskId,
  setSelectedSectionValue,
  selectedTaskIds,
  setselectedTaskIds,
  filterTaskDateRangeType,
  filterTaskFromDate,
  filterTaskToDate,
  filterTaskCustomIds,
  filterTaskTitle,
  filterTaskLabels,
  filterTaskStatus,
  filterTaskPriorities,
  filterTaskAssignedByUsers,
  filterTaskAssignedToUsers,
  // resetFilterData,
  afterSavedTaskData = null,
  setAfterSavedTaskData = () => {},
}) => {
  const placeHolderCount = [1, 2, 3];

  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);

  //get all tasks
  const getAllTask = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TASKS +
        `?token=${token}&projectid=${params.id}&sectionid=${sectionData._id}&ismoderator=${isProjectModerator}`;

      if (filterTaskDateRangeType != "") {
        requestUrl =
          requestUrl + `&filterdaterangetype=${filterTaskDateRangeType}`;
      }

      if (filterTaskFromDate != "" || filterTaskToDate != "") {
        requestUrl =
          requestUrl +
          `&filterfromdate=${filterTaskFromDate}&filtertodate=${filterTaskToDate}`;
      }

      if (filterTaskCustomIds.length > 0) {
        requestUrl = requestUrl + `&filtertaskcustomids=${filterTaskCustomIds}`;
      }

      if (filterTaskTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTaskTitle}`;
      }

      if (filterTaskLabels.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTaskLabels}`;
      }
      if (filterTaskStatus.length > 0) {
        requestUrl = requestUrl + `&filtercompletestatus=${filterTaskStatus}`;
      }
      if (filterTaskPriorities.length > 0) {
        requestUrl = requestUrl + `&filterpriorities=${filterTaskPriorities}`;
      }

      if (filterTaskAssignedByUsers.length > 0) {
        requestUrl =
          requestUrl + `&filtermoderators=${filterTaskAssignedByUsers}`;
      }

      if (filterTaskAssignedToUsers.length > 0) {
        requestUrl = requestUrl + `&filterusers=${filterTaskAssignedToUsers}`;
      }

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log("response in task list------->", response);
      setIsLoading(false);

      if (response.status) {
        setTaskList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select task
  const taskSelectionHandler = (e) => {
    let checkBoxArr = [...selectedTaskIds];

    if (checkBoxArr.includes(e.target.value)) {
      let key = checkBoxArr.indexOf(e.target.value);
      checkBoxArr.splice(key, 1);
    } else {
      checkBoxArr.push(e.target.value);
    }

    setselectedTaskIds(checkBoxArr);
  };

  useEffect(() => {
    if (sectionData) {
      getAllTask();
    }
  }, [sectionData]);

  useEffect(() => {
    if (
      filterTaskDateRangeType != "" ||
      filterTaskFromDate != "" ||
      filterTaskToDate != "" ||
      filterTaskCustomIds.length > 0 ||
      filterTaskTitle != "" ||
      filterTaskLabels.length > 0 ||
      filterTaskPriorities.length > 0 ||
      filterTaskAssignedByUsers.length > 0 ||
      filterTaskAssignedToUsers.length > 0 ||
      filterTaskStatus.length > 0
    ) {
      getAllTask();
    }
  }, [
    filterTaskDateRangeType,
    filterTaskFromDate,
    filterTaskToDate,
    filterTaskCustomIds,
    filterTaskTitle,
    filterTaskLabels,
    filterTaskStatus,
    filterTaskPriorities,
    filterTaskAssignedByUsers,
    filterTaskAssignedToUsers,
  ]);

  useEffect(() => {
    if (afterSavedTaskData) {
      // console.log("sectionData", sectionData);
      // console.log("afterSavedTaskData---->", afterSavedTaskData);

      if (
        afterSavedTaskData.projectsection.toString() ===
        sectionData._id.toString()
      ) {
        setTaskList((prevTasks) => {
          const existingIndex = taskList.findIndex(
            (task) => task._id.toString() === afterSavedTaskData._id.toString()
          );

          if (existingIndex !== -1) {
            // task exists, update the existing record
            const updatedTasks = [...prevTasks];
            updatedTasks[existingIndex] = afterSavedTaskData;
            return updatedTasks;
          } else {
            // Roll number does not exist, add new student at index 0
            return [afterSavedTaskData, ...prevTasks];
          }
        });
      }
      setAfterSavedTaskData(null);
    }
  }, [afterSavedTaskData, sectionData]);

  return (
    <div className="table-wrapper">
      <div className="table-responsive mb-3">
        <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
          {isLoading ? (
            <tbody>
              {placeHolderCount.map((count, index) => {
                return (
                  <tr className="completed" key={index}>
                    <td className="px-3 border-bottom-0" width="50">
                      <div className="form-check p-0 m-0">
                        <input
                          className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                          type="checkbox"
                          name=""
                          id=""
                        />
                      </div>
                    </td>
                    <td className="px-3 border-bottom-0" width="50%">
                      <span
                        className="placeholder"
                        style={{
                          width: "8.125rem",
                          height: "1.125rem",
                        }}
                      ></span>
                      <div className="tags_info d-flex align-items-center gap-2 mt-2">
                        <label className="d-flex m-0 align-items-center gap-1">
                          <i className="material-symbols-outlined fs-md icon-fill text-gray">
                            sell
                          </i>
                          <span
                            className="text-gray fs-xs placeholder"
                            style={{
                              width: "2rem",
                            }}
                          ></span>
                        </label>
                        <span
                          className="text-gray fs-xs placeholder"
                          style={{
                            width: "4rem",
                          }}
                        ></span>

                        <span
                          className="text-gray fs-xs placeholder"
                          style={{
                            width: "4rem",
                          }}
                        ></span>
                      </div>
                    </td>
                    <td className="px-3 border-bottom-0">
                      <span
                        className="placeholder rounded-90"
                        style={{
                          width: "4.375rem",
                          height: "1.75rem",
                        }}
                      ></span>
                    </td>
                    <td className="px-3 border-bottom-0">
                      <span
                        className="placeholder"
                        style={{
                          width: " 5rem",
                          height: "1rem",
                        }}
                      ></span>
                    </td>
                    <td className="px-3 border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <span
                          className="placeholder rounded-circle"
                          style={{
                            width: "1.375rem",
                            height: "1.375rem",
                          }}
                        ></span>
                        <span
                          className="placeholder"
                          style={{
                            width: "3.125rem",
                            height: "0.875rem",
                          }}
                        ></span>
                      </div>
                    </td>
                    <td className="px-3 border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span
                          className="placeholder rounded-circle"
                          style={{
                            width: "1.125rem",
                            height: "1.125rem",
                          }}
                        ></span>
                        <span
                          className="placeholder"
                          style={{
                            width: "2.188rem",
                            height: "0.875rem",
                          }}
                        ></span>
                      </p>
                    </td>
                    <td className="px-3 border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span
                          className="placeholder rounded-circle"
                          style={{
                            width: "1.125rem",
                            height: "1.125rem",
                          }}
                        ></span>
                        <span
                          className="placeholder"
                          style={{
                            width: "2.188rem",
                            height: "0.875rem",
                          }}
                        ></span>
                      </p>
                    </td>
                    <td className="px-3 border-bottom-0">
                      <span
                        className="placeholder rounded-90"
                        style={{
                          width: "2.5rem",
                          height: "1.75rem",
                        }}
                      ></span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {/* <!-- ========== Start task list map table Section ========== --> */}
              {taskList.map((taskData, index2) => {
                return (
                  <tr key={index2}>
                    {/* <!-- ========== Start checkbox column ========== --> */}
                    <td className="px-3 border-bottom-0" width="50">
                      <div className="form-check p-0 m-0">
                        <input
                          className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                          type="checkbox"
                          name="taskCheckBox"
                          id="taskCheckBox"
                          value={taskData._id}
                          onChange={taskSelectionHandler}
                        />
                      </div>
                    </td>
                    {/* <!-- ========== End checkbox column ========== --> */}

                    {/* <!-- ========== Start id,tasktitle,tag column ========== --> */}
                    <td className="px-3 border-bottom-0" width="50%">
                      <p className="fs-md fw-semibold d-flex align-items-center gap-2">
                        <span className="badge bg-primary">
                          {taskData.customid}
                        </span>
                        {taskData.title}
                      </p>
                      <div className="tags_info d-flex align-items-center gap-2 mt-2">
                        <label className="d-flex m-0 align-items-center gap-1">
                          <i className="material-symbols-outlined fs-md icon-fill text-gray">
                            sell
                          </i>
                          <span className="text-gray fs-xs">{t("Tags")}</span>
                        </label>
                        <ul className="list-style-none d-flex align-items-center gap-2">
                          {taskData.tags.map((tagItem, index2) => {
                            return (
                              <li key={index2}>
                                <Link
                                  to="#"
                                  className="body-bg rounded-90 py-1 px-2 fs-xs text-gray"
                                >
                                  {tagItem.title}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </td>
                    {/* <!-- ========== End id,tasktitle,tag column ========== --> */}

                    {/* <!-- ========== Start task status column ========== --> */}
                    <td className="px-3 border-bottom-0">
                      {taskData.completedpercent == "0" ? (
                        <span className="d-inline-block fs-xs lh-1 p-2 bg-gray-300 rounded-90">
                          Pending
                        </span>
                      ) : taskData.completedpercent == "1" ? (
                        <span className="d-inline-block fs-xs text-white lh-1 p-2 gradient-dark rounded-90">
                          In Progress
                        </span>
                      ) : taskData.completedpercent == "2" ? (
                        <span className="d-inline-block fs-xs lh-1 text-white p-2 bg-primary rounded-90">
                          Testing
                        </span>
                      ) : (
                        <span className="d-inline-block fs-xs text-white lh-1 p-2 bg-success rounded-90">
                          Completed
                        </span>
                      )}
                    </td>
                    {/* <!-- ========== End task status column ========== --> */}

                    {/* <!-- ========== Start task date column ========== --> */}
                    <td className="px-3 border-bottom-0">
                      <p className="fs-sm">
                        {/* convert date in spanish format */}
                        {taskData.taskstartdate}
                      </p>
                    </td>
                    {/* <!-- ========== End task date column ========== --> */}

                    {/* <!-- ========== Start task assinged user column ========== --> */}
                    <td className="px-3 border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{
                            width: "30px",
                            height: "30px",
                          }}
                        >
                          {taskData.assignedusername === "" ? null : (
                            <img
                              src={
                                taskData.assigneduserimage == ""
                                  ? assetImages.defaultUser
                                  : url.SERVER_URL + taskData.assigneduserimage
                              }
                              alt=""
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          )}
                        </div>
                        <p className="fs-sm">{taskData.assignedusername}</p>
                      </div>
                    </td>
                    {/* <!-- ========== End task assinged user column ========== --> */}

                    {/* <!-- ========== Start task hours column ========== --> */}
                    <td className="px-3 border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-md">
                          schedule
                        </span>
                        <span className="d-block fs-sm">
                          {taskData.hours}Hr
                        </span>
                      </p>
                    </td>
                    {/* <!-- ========== End task hours column ========== --> */}

                    {/* <!-- ========== Start log hour column ========== --> */}
                    <td className="px-3 border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-md">
                          work_history
                        </span>
                        <span className="d-block fs-sm">
                          {taskData.loggedhours}Hr
                        </span>
                      </p>
                    </td>
                    {/* <!-- ========== End log hour column ========== --> */}

                    {/* <!-- ========== Start task complete percent column ========== --> */}
                    <td className="px-3 border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block fs-sm">
                          {taskData.taskpercent}
                        </span>
                        <span className="d-block material-symbols-outlined icon-md">
                          percent
                        </span>
                      </p>
                    </td>
                    {/* <!-- ========== End task complete percent column ========== --> */}

                    {/* <!-- ========== Start task priority column ========== --> */}
                    <td className="px-3 border-bottom-0">
                      {taskData.priority == "1" ? (
                        <span className="d-inline-block fs-xs lh-1 text-white p-2 bg-primary rounded-90">
                          High
                        </span>
                      ) : taskData.priority == "2" ? (
                        <span className="d-inline-block fs-xs text-white lh-1 p-2 gradient-dark rounded-90">
                          Normal
                        </span>
                      ) : (
                        <span className="d-inline-block fs-xs lh-1 p-2 bg-gray-300 rounded-90">
                          Low
                        </span>
                      )}
                    </td>
                    {/* <!-- ========== End task priority column ========== --> */}

                    {/* <!-- ========== Start edit and delete button column ========== --> */}
                    <td className="px-3 border-bottom-0">
                      <div className="d-flex gap-2">
                        <Link
                          to="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#addTask_view"
                          aria-controls="addTask_view"
                          onClick={() => {
                            setSelectedEditTaskId(taskData._id);
                            setSelectedSectionValue({
                              label: sectionData.title,
                              value: sectionData._id,
                              startdate: sectionData.startdate,
                              enddate: sectionData.enddate,
                            });
                          }}
                        >
                          <span className="d-block material-symbols-outlined icon-lg">
                            {isProjectModerator ? "edit" : "chevron_right"}
                          </span>
                        </Link>
                        {isProjectModerator ? (
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#projectTaskDeleteModal"
                            onClick={() => {
                              console.log("task id----->", taskData._id);
                              setDeleteType("singletask");
                              setSelectedEditTaskId(taskData._id);
                            }}
                          >
                            <span className="d-block material-symbols-outlined icon-lg">
                              delete
                            </span>
                          </Link>
                        ) : null}
                      </div>
                    </td>
                    {/* <!-- ========== End edit and delete button column ========== --> */}
                  </tr>
                );
              })}
              {/* <!-- ========== End task list map table Section ========== --> */}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default TaskTable;
