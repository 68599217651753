/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

const AppUserListHeader = ({
  reloadUserList,
  changeStatusHandler,
  // changeAdminStatusHandler,
  updateRankHandler,
}) => {
  const { t } = useTranslation(); // for translations

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("App Users") }];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ----- filter search section start ----- */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbText} />
        </div>
        {/* ----- filter search section end ----- */}
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={reloadUserList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNewMail"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    mail
                  </span>
                  <span className="d-block">{t("Send Mail")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              {/* ----- update rank section start -----  */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  onClick={updateRankHandler}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    progress_activity
                  </span>
                  <span className="d-block">{t("Update Rank")}</span>
                </Link>
              </li>
              {/* ----- update rank section end -----  */}
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    changeStatusHandler("1");
                  }}
                  className="dropdown-item d-flex align-items-center gap-1"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    check_circle
                  </span>
                  <span className="d-block">{t("Enable Selected")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    changeStatusHandler("0");
                  }}
                  className="dropdown-item d-flex align-items-center gap-1"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    cancel
                  </span>
                  <span className="d-block">{t("Disable Selected")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#saveDirectAccess"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    switch_access_2
                  </span>
                  <span className="d-block">{t("Add Direct Access")}</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="#"
                  onClick={() => {
                    console.log("delete link");
                  }}
                  className="dropdown-item d-flex align-items-center gap-1 text-danger"
                >
                  <span className="d-block">Delete Selected</span>
                </Link>
              </li> */}
            </ul>
          </div>
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasUserFilter"
            aria-controls="offcanvasUserFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
          <Link
            to="/admin/appuser/profile"
            className="btn btn-primary d-flex align-items-center gap-1"
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">{t("Add New")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default AppUserListHeader;
