/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

import AddLabelModal from "components/Common/Modal/AddLabelModal";
import CourseListFilterPopup from "../Popup/CourseListFilterPopup";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";
import CourseListHeader from "../Header/CourseListHeader";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

const CourseListBody = () => {
  const history = useHistory();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation

  const [isLoading, setIsLoading] = useState(false);
  const [courseList, setCourseList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedCourseIds, setselectedCourseIds] = useState([]);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterTitle, setFilterTitle] = useState("");
  const [filterCode, setFilterCode] = useState("");
  const [filterLanguage, setFilterLanguage] = useState("");
  const [filterTags, setFilterTags] = useState("");

  //get course list
  const getAllCourses = async () => {
    setRowSelection({});
    setselectedCourseIds([]);
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_COURSE_RECORDS + `?token=${token}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestURL += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterTitle != "") {
        requestURL += `&filtertitle=${filterTitle}`;
      }

      if (filterCode != "") {
        requestURL += `&filtercode=${filterCode}`;
      }

      if (filterLanguage != "") {
        requestURL += `&filterlanguage=${filterLanguage}`;
      }

      if (filterTags != "") {
        requestURL += `&filtertags=${filterTags}`;
      }

      console.log("Course url", requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setCourseList(response.data);
      } else {
        setAlertMessage("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //function for delete course
  const deleteMassiveCourseHandler = async () => {
    if (selectedCourseIds.length > 0) {
      const courseListToBeDelete = courseList.filter((item) =>
        selectedCourseIds.includes(item._id)
      );

      try {
        let courseData = {
          courselist: courseListToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_MASSIVE_COURSE + `?token=${token}`;

        const response = await putData(requestURL, courseData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllCourses();
          }, 2500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
      setShowAlert(true);
    }
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedCourseList = [...courseList];

    const rowIndex = updatedCourseList.findIndex(
      (course) => course._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedCourseList[rowIndex].rank = value;
      setCourseList(updatedCourseList);
    }
  };

  //function for update rank
  const updateBulkCourseHandler = async (e) => {
    if (selectedCourseIds.length > 0) {
      try {
        const courseListToBeUpdate = courseList.filter((item) =>
          selectedCourseIds.includes(item._id)
        );

        let courseData = {
          courselist: courseListToBeUpdate,
        };

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_MASSIVE_COURSE + `?token=${token}`;

        const response = await putData(requestURL, courseData);

        console.log(response);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllCourses();
          }, 2500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
    }
    setShowAlert(true);
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: t("Course"),
        size: 250,
      },
      {
        accessorKey: "moderatorname",
        header: t("Owner"),
        size: 250,
      },
      {
        accessorKey: "rank",
        header: t("Rank"),
        Cell: ({ row }) => (
          <div className="ranking border-bottom-0">
            {row.original.moderatorid.toString() === userInfo._id.toString() ||
            userInfo.role.slug === "ADMIN" ||
            userInfo.role.slug === "SUPER_ADMIN" ? (
              <input
                type="number"
                className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                style={{ width: "100px" }}
                value={row.original.rank}
                onChange={(e) => rankInputChangeHandler(row, e.target.value)}
              />
            ) : (
              <p className="ps-3">{row.original.rank}</p>
            )}
          </div>
        ),
      },
      {
        accessorKey: "code",
        header: t("Code"),
      },
      {
        accessorKey: "courselanguage",
        header: t("Language"),
      },
      {
        accessorKey: "chaptercount",
        header: t("Chapters"),
      },
      {
        accessorKey: "lessoncount",
        header: t("Lessons"),
      },
      {
        accessorKey: "membercount",
        header: t("Team"),
      },
      {
        accessorKey: "subscribercount",
        header: t("Subscribers"),
      },
      {
        accessorKey: "taskcount",
        header: t("Tasks"),
      },
      {
        accessorKey: "coursedate",
        header: t("Date"),
      },
      {
        accessorKey: "parentcourse",
        header: t("Parent Course"),
        size: 200,
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
        // Filter: globalFilterTextHandler,
      },
    ],
    [courseList, i18n.language]
  );

  useEffect(() => {
    getAllCourses();
  }, []);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setselectedCourseIds(selectedIdsArray);
    } else {
      setselectedCourseIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COURSE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* ---- header area ----*/}
            <CourseListHeader
              reloadList={getAllCourses}
              bulkUpdateHandler={updateBulkCourseHandler}
            />

            {/* ---- table section ---- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={courseList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="edit">
                      <Link
                        className="dropdown-item"
                        to={`/admin/course/save/${row.id}`}
                      >
                        {t("View Details")}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="team">
                      <Link
                        className="dropdown-item"
                        to={`/admin/course/team/${row.id}`}
                      >
                        {t("View Members")}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="chapter">
                      <Link
                        className="dropdown-item"
                        to={`/admin/course/chapter/list/${row.id}`}
                      >
                        {t("View Chapter")}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="lesson">
                      <Link
                        className="dropdown-item"
                        to={`/admin/course/lessons/list/${row.id}`}
                      >
                        {t("View Lesson")}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="subscriber">
                      <Link
                        className="dropdown-item"
                        to={`/admin/course/subscribers/${row.id}`}
                      >
                        {t("View Subscribers")}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="task">
                      <Link
                        className="dropdown-item"
                        to={`/admin/course/task/list/${row.id}`}
                      >
                        {t("View Task Submitted")}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="faq">
                      <Link
                        className="dropdown-item"
                        to={`/admin/course/faq/${row.id}`}
                      >
                        {t("View Faq")}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="certification">
                      <Link
                        className="dropdown-item"
                        to={`/admin/course/certification/${row.id}`}
                      >
                        {t("View Certification")}
                      </Link>
                    </MenuItem>,
                  ]} // action columns menu items
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ======== popups and modals area ======== */}
        {/* ------ add label modal-------- */}
        <AddLabelModal
          moduleName="course"
          afterTagModalClose={() => {
            setTimeout(() => {
              getAllCourses();
            }, 2000);
          }}
          selectedIds={selectedCourseIds}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          setShowAlert={setShowAlert}
        />

        {/* ------- filter popup ------- */}
        <CourseListFilterPopup
          functionCall={getAllCourses}
          setFilterStartDate={setFilterStartDate}
          setFilterEndDate={setFilterEndDate}
          setFilterTitle={setFilterTitle}
          setFilterCode={setFilterCode}
          setFilterLanguage={setFilterLanguage}
          setFilterTags={setFilterTags}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setselectedCourseIds([]);
          }}
          onDelete={deleteMassiveCourseHandler}
          moduleName={t("course")}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CourseListBody;
