import Header from "components/Common/Header/Header";
import ChallengeDashboardBody from "components/ChallengeComponents/Dashboard/DashboardBody/ChallengeDashboardBody"

import React, { useEffect } from "react";

const ChallengeDashboard = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Management";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />
      {/* ---- dashboard body ---- */}
      <ChallengeDashboardBody />
    </main>
  );
};

export default ChallengeDashboard;
