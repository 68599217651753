/* eslint-disable */
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

// import for excel download
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

//import dnds
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import { formatNumber } from "helper/Common/CommonHelper";

//import common header objects
import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import CrmLeadKanbanHeader from "../Header/CrmLeadKanbanHeader";
import SaveColumnModal from "components/Common/Modal/SaveColumnModal";
import SaveActivityProcessPopup from "components/Common/Popup/ActivityProcessPopup/SaveActivityProcessPopup";
import AddProcessFromTemplateModal from "components/Common/Modal/AddProcessFromTemplateModal";
import KanbanBoard from "./LeadKanbanBoardComponents/KanbanBoard/KanbanBoard";
import CrmLeadKanbanFilterPopup from "../Popup/CrmLeadKanbanFilterPopup";
import SaveDirectAccessModal from "components/Common/Modal/SaveDirectAccessModal";

const CrmLeadKanbanBody = () => {
  const moduleSlug = "MOD_LEAD_KANBAN";
  const moduleName = "crmleadkanban";

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  // custom style for select process
  const customProcessStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      color: "#666666",
      fontSize: "0.875rem",
      fontWeight: "normal",
      display: "block",
      minHeight: "1.2em",
      // whiteSpace: "nowrap",
    }),

    // Value style
    control: (styles, state) => ({
      ...styles,
      fontSize: "0.875rem",
      borderRadius: "0.5rem",
      minWidth: 296,
      border: state.isFocused ? "1px solid #D4D4D4" : "1px solid #D4D4D4",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? "1px solid #D4D4D4" : "1px solid #D4D4D4",
      },
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "0.875rem",
      };
    },

    // Indicator style
    // dropdownIndicator: (base, state) => {
    //   let changes = { color: "#505050" };
    //   return Object.assign(base, changes);
    // },
  };

  const [showEmptyProcessMessage, setShowEmptyProcessMessage] = useState(false);
  const [processListLoading, setprocessListLoading] = useState(false);
  const [processOwnerValue, setprocessOwnerValue] = useState(null);
  const [isProcessOwner, setisProcessOwner] = useState(false);
  const [moderatorName, setModeratorName] = useState("");
  const [processList, setProcessList] = useState([]);

  const [kanbanData, setKanbanData] = useState([]);

  const [processDetailsLoading, setProcessDetailsLoading] = useState(false);

  //selected process
  const [selectedProcessvalue, setSelectedProcessvalue] = useState(null);
  const [selectedProcessId, setSelectedProcessId] = useState(null);

  //filter
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterLeadName, setFilterLeadName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [filterCompany, setFilterCompany] = useState("");
  const [filterPosition, setFilterPosition] = useState("");
  const [filterTransactionModule, setFilterTransactionModule] = useState("");
  const [filterTransactionElement, setFilterTransactionElement] = useState("");
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterMinSalary, setFilterMinSalary] = useState("");
  const [filterMaxSalary, setFilterMaxSalary] = useState("");
  const [filterLabels, setFilterLabels] = useState([]);
  const [filterSubsribeStatus, setFilterSubsribeStatus] = useState("");

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [isNoDefaultFilter, setIsNoDefaultFilter] = useState(false);

  // KPI's to show in kanban selected process
  const [followersCount, setFollowersCount] = useState(0);
  const [cardItemCount, setCardItemCount] = useState(0);

  const [selectedComponentTagId, setSelectedComponentTagId] = useState(null); //for create new record

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  //get all process
  const getAllProcess = async () => {
    try {
      setprocessListLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_PROCESS +
        `?token=${token}&ismod=${true}&istemplate=${false}&moduleslug=${moduleSlug}`;

      const response = await getData(requestURL);

      setprocessListLoading(false);

      console.log("response user all process----->", response);

      if (response.status) {
        if (response.data.length === 0) {
          setShowEmptyProcessMessage(true);
        } else {
          setShowEmptyProcessMessage(false);
        }

        const processOptions = response.data.map((item) => ({
          label: `${item.title} ${item.isowner ? "(Owned)" : ""}`,
          value: item._id,
        }));

        if (response.lastsavedprocessid) {
          proecessSelectionHandler(
            processOptions.find(
              (item) => item.value === response.lastsavedprocessid
            )
          );
        } else {
          proecessSelectionHandler(processOptions[processOptions.length - 1]);
        }

        setProcessList(processOptions);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //select process
  const proecessSelectionHandler = (val) => {
    if (val) {
      setSelectedProcessvalue(val);
      getProcessDetails(val.value);
      setSelectedProcessId(val.value);
    } else {
      setSelectedProcessvalue(null);
      getProcessDetails(null);
      setSelectedProcessId(null);
    }
  };

  //get process details
  const getProcessDetails = async (selectedProcessId) => {
    if (selectedProcessId) {
      setProcessDetailsLoading(true);

      setCardItemCount(0);

      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_GET_PROCESS_DETAILS +
          `/${selectedProcessId}?token=${token}&moduleslug=${moduleSlug}`;

        if (filterStartDate !== "" && filterEndDate !== "") {
          requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
        }

        if (filterLeadName != "") {
          requestUrl = requestUrl + `&filtername=${filterLeadName}`;
        }

        if (filterEmail != "") {
          requestUrl = requestUrl + `&filteremail=${filterEmail}`;
        }

        if (filterPhone != "") {
          requestUrl = requestUrl + `&filterphone=${filterPhone}`;
        }

        if (filterLocation != "") {
          requestUrl = requestUrl + `&filterlocation=${filterLocation}`;
        }

        if (filterPosition != "") {
          requestUrl = requestUrl + `&filterposition=${filterPosition}`;
        }

        if (filterCompany != "") {
          requestUrl = requestUrl + `&filtercompany=${filterCompany}`;
        }

        if (filterMinSalary != "") {
          requestUrl = requestUrl + `&filterminsalary=${filterMinSalary}`;
        }

        if (filterMaxSalary != "") {
          requestUrl = requestUrl + `&filtermaxsalary=${filterMaxSalary}`;
        }

        if (filterTransactionModule != "") {
          requestUrl =
            requestUrl + `&filtertransactionmodule=${filterTransactionModule}`;
        }

        if (filterTransactionElement != "") {
          requestUrl =
            requestUrl +
            `&filtertransactionelement=${filterTransactionElement}`;
        }

        if (filterSkills.length > 0) {
          requestUrl = requestUrl + `&filterskills=${filterSkills}`;
        }

        if (filterSubsribeStatus !== "") {
          requestUrl =
            requestUrl + `&filtersubsribestatus=${filterSubsribeStatus}`;
        }

        const response = await getData(requestUrl);

        setProcessDetailsLoading(false);

        console.log("Process Details Response >> ", response);

        await saveProcessHistoryHandler(selectedProcessId);

        if (response.status && response.data) {
          setMessageType("success");
          setisProcessOwner(response.data.isuserprocessowner);
          setKanbanData(response.data.processlabels);

          setFollowersCount(
            response.data.followers ? response.data.followers.length : 0
          );
          setModeratorName(response.data?.moderatorname);

          setprocessOwnerValue([response.data?.processownervalue]);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }

      setShowAlert(true);
    } else {
      setKanbanData([]);
    }
  };

  //save process history
  const saveProcessHistoryHandler = async (processId) => {
    try {
      let historyData = {
        activityprocessid: processId,
        moduleslug: moduleSlug,
      };
      let requestURL =
        url.API_BASE_URL + url.API_SAVE_PROCESS_HISTORY + `?token=${token}`;

      await postData(requestURL, historyData);
    } catch (error) {
      console.log(error.message);
    }
  };

  //collapse column function
  const collapseHandler = (isCollapsed, componentId) => {
    const updatedKanbanData = kanbanData.map((kanban) => {
      if (kanban.componentid === componentId) {
        return { ...kanban, iscollapsed: isCollapsed };
      }
      return kanban;
    });

    setKanbanData(updatedKanbanData);
  };

  //after Add New  Column
  const afterAddNewColumn = () => {
    if (selectedProcessId) {
      getProcessDetails(selectedProcessId);
    }
  };

  //delete task from board
  const deleteLeadHandler = async (lead, tagId) => {
    try {
      let leadData = {
        leadid: lead._id,
        componentorderitemid: lead.componentorderitemid,
        tagid: tagId,
      };

      console.log(leadData);

      let requestURL =
        url.API_BASE_URL + url.API_REMOVE_CARD_ITEM_KANBAN + `?token=${token}`;

      const response = await putData(requestURL, leadData);

      // console.log(response);

      if (response.status) {
        setMessageType("success");
        getProcessDetails(selectedProcessId);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //create chat
  const createContactChatHandler = async (
    userId = null,
    componentTitle = ""
  ) => {
    if (userId && userId.toString() !== userInfo._id.toString()) {
      try {
        let requestURL =
          url.API_BASE_URL +
          url.API_SEARCH_CONTACT +
          `?token=${token}&member=${userId}`;

        const response = await getData(requestURL);

        console.log(response);

        if (response.status) {
          const externalUrl = `${url.FRONTEND_BASE_URL}/conversation/contact/${response.data._id}/MOD_CONTACT/${moduleName}/${componentTitle}`; // Replace with your external URL
          // const externalUrl = `${url.FRONTEND_BASE_URL}/conversation/contact/${response.data._id}/MOD_CONTACT`; // Replace with your external URL

          window.open(externalUrl, "_blank", "noopener,noreferrer");
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
    }
  };

  //drag and drop operation
  const onDragEnd = async (result) => {
    // console.log("onDragEnd", result);
    const { destination, source, draggableId, type } = result;

    //if no destination or no drag and drop return.
    if (!destination) {
      return;
    }

    //update kanban table
    let requestURL =
      url.API_BASE_URL +
      url.API_UPDATE_KANBAN +
      `?token=${token}&changetype=${type}`;

    //check the drag and drop type is column
    if (type === "column") {
      // Reordering columns
      const newColumnOrder = Array.from(kanbanData);
      newColumnOrder.splice(source.index, 1)[0]; // Remove from source index
      newColumnOrder.splice(
        destination.index,
        0,
        kanbanData.find((column) => column.componentid === draggableId)
      ); // Insert at destination index

      // Update componentorder field based on new positions
      newColumnOrder.forEach((column, index) => {
        column.componentorder = index + 1; // Adding 1 because componentorder starts from 1
      });

      //update local state
      setKanbanData(newColumnOrder);

      //send data to server for update changes
      const updateKanbanData = {
        source: null,
        destination: null,
        processid: selectedProcessId,
        processlist: newColumnOrder,
        draggableid: draggableId,
        updatedcolumn: null,
      };

      try {
        const response = await putData(requestURL, updateKanbanData);

        if (response.status) {
          setMessageType("success");
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    }

    if (type === "lead") {
      // Moving task within a column or to another column
      const sourceColumn = kanbanData.find(
        (column) => column.tagid.toString() === source.droppableId.toString()
      );

      const destinationColumn = kanbanData.find(
        (column) =>
          column.tagid.toString() === destination.droppableId.toString()
      );

      // If the task is moved within the same column
      if (
        source.droppableId.toString() === destination.droppableId.toString()
      ) {
        const newSourceColumnLeads = sourceColumn.leadlist;

        const updatedLeadData = sourceColumn.leadlist.filter(
          (item) => item._id.toString() === draggableId.toString()
        );

        newSourceColumnLeads.splice(source.index, 1); // Remove from source index

        newSourceColumnLeads.splice(destination.index, 0, updatedLeadData[0]); // Insert at destination index

        newSourceColumnLeads.forEach((lead, index) => {
          lead.order = index + 1;
        });

        const updatedColumn = {
          ...sourceColumn,
          leadlist: newSourceColumnLeads,
        };

        const newColumnInvoices = kanbanData.map((column) => {
          return column.componentid.toString() ===
            sourceColumn.componentid.toString()
            ? updatedColumn
            : column;
        });

        setKanbanData(newColumnInvoices);

        //send data to server for update changes
        const updateKanbanData = {
          source: source.droppableId,
          destination: destination.droppableId,
          processlist: newColumnInvoices,
          updatedsourcecolumn: updatedColumn,
          processid: selectedProcessId,
          draggableid: draggableId,
        };

        try {
          const response = await putData(requestURL, updateKanbanData);

          if (response.status) {
            setMessageType("success");
          } else {
            setMessageType("error");
          }
          setAlertMessage(response.message);
        } catch (error) {
          setMessageType("error");
          setAlertMessage(error.message);
        }
      } else {
        // If the lead is moved to another column
        const sourceLeadList = sourceColumn.leadlist;
        const destinationLeadList = destinationColumn.leadlist;

        const updatedLeadData = sourceColumn.leadlist.filter(
          (item) => item._id.toString() === draggableId.toString()
        );

        sourceLeadList.splice(source.index, 1); // Remove from source index

        destinationLeadList.splice(destination.index, 0, updatedLeadData[0]); // Insert at destination index

        sourceLeadList.forEach((lead, index) => {
          lead.order = index + 1;
        });

        const updatedSourceColumn = {
          ...sourceColumn,
          leadlist: sourceLeadList,
        };

        //add tag of destination component id which is tag id.
        destinationLeadList.forEach((destinationLeadData, index) => {
          if (destinationLeadData.tags.includes(source.droppableId)) {
            destinationLeadData.tags.splice(source.droppableId, 1);
            destinationLeadData.tags.push(destination.droppableId);
          }
          destinationLeadData.order = index + 1;
        });

        const updatedDestinationColumn = {
          ...destinationColumn,
          leadlist: destinationLeadList,
        };

        const newColumnInvoices = kanbanData.map((column) => {
          if (
            column.componentid.toString() ===
            sourceColumn.componentid.toString()
          ) {
            return updatedSourceColumn;
          }
          if (
            column.componentid.toString() ===
            destinationColumn.componentid.toString()
          ) {
            return updatedDestinationColumn;
          }
          return column;
        });

        setKanbanData(newColumnInvoices);

        //send data to server for update changes
        const updateKanbanData = {
          source: source.droppableId,
          destination: destination.droppableId,
          processlist: newColumnInvoices,
          updatedsourcecolumn: updatedSourceColumn,
          updateddestinationcolumn: updatedDestinationColumn,
          processid: selectedProcessId,
          draggableid: draggableId,
        };

        try {
          const response = await putData(requestURL, updateKanbanData);

          if (response.status) {
            setMessageType("success");
          } else {
            setMessageType("error");
          }
          setAlertMessage(response.message);
        } catch (error) {
          setMessageType("error");
          setAlertMessage(error.message);
        }
      }
    }

    setShowAlert(true);

    setTimeout(() => {
      getProcessDetails(selectedProcessId);
    }, 1000);
  };

  //function for close process template modal
  const afterProcessModalClose = () => {
    getAllProcess();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterSubsribeStatus("");
    setfilterStartDate("");
    setfilterEndDate("");
    setFilterLeadName("");
    setFilterEmail("");
    setFilterLocation("");
    setFilterPhone("");
    setFilterCompany("");
    setFilterPosition("");
    setFilterTransactionModule("");
    setFilterTransactionElement("");
    setFilterSkills([]);
    setFilterMinSalary("");
    setFilterMaxSalary("");
    setFilterLabels([]);

    setReloadData(true);
  };

  useEffect(() => {
    getAllProcess();
  }, []);

  useEffect(() => {
    if (
      (filterStartDate !== "" && filterEndDate !== "") ||
      filterLocation != "" ||
      filterLeadName != "" ||
      filterEmail != "" ||
      filterCompany != "" ||
      filterPhone != "" ||
      filterPosition != "" ||
      filterMinSalary != "" ||
      filterMaxSalary != "" ||
      filterTransactionModule != "" ||
      filterTransactionElement != "" ||
      filterSkills.length > 0 ||
      filterLabels.length > 0 ||
      filterSubsribeStatus !== ""
    ) {
      getProcessDetails(selectedProcessId);
    }
  }, [
    filterSubsribeStatus,
    filterStartDate,
    filterEndDate,
    filterLocation,
    filterLeadName,
    filterEmail,
    filterCompany,
    filterPhone,
    filterPosition,
    filterMinSalary,
    filterMaxSalary,
    filterSkills,
    filterLabels,
    filterTransactionModule,
    filterTransactionElement,
  ]);

  useEffect(() => {
    if (isNoDefaultFilter) {
      getProcessDetails(selectedProcessId);
    }
  }, [isNoDefaultFilter]);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getProcessDetails(selectedProcessId);
      setReloadData(false);
    }
  }, [reloadData]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_LEAD"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Leads")}
          />

          <div className="container-fluid px-lg-5">
            <CrmLeadKanbanHeader reloadLeadList={resetFilterData} />

            {processListLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : showEmptyProcessMessage ? (
              <div className="challenges_empty text-center">
                <div className="empty_pic mb-4">
                  {" "}
                  <img src={assetImages.emptyVector} alt="" />
                </div>
                <div className="empty_text">
                  <p className="fs-lg text-gray fw-semibold mb-4">
                    You have not created any process yet please create one to
                    start
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <Link
                      to="#"
                      className="btn btn-outline-primary d-flex align-items-center gap-1"
                      data-bs-toggle="modal"
                      data-bs-target="#kanbanExample"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">
                        Add Process From Templates
                      </span>
                    </Link>
                    <Link
                      to="#activityProcessOffCanvas"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveProcess"
                      className="btn btn-primary d-inline-flex align-items-center gap-1"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">Add Process</span>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="project-tasks-container">
                <div className="challenges-process-row d-flex align-items-center justify-content-between gap-3 mb-1">
                  <div className="left-process-select d-flex align-items-center gap-3">
                    <Select
                      // className="form-select fs-sm shadow-none"
                      isClearable
                      placeholder="Select process"
                      options={processList}
                      value={selectedProcessvalue}
                      onChange={(val) => proecessSelectionHandler(val)}
                      styles={customProcessStyle}
                    />

                    <div className="d-flex align-items-center gap-2">
                      <span>
                        <b>Moderator:</b> {moderatorName}{" "}
                      </span>
                      <span>
                        <b>Team:</b> {followersCount}{" "}
                      </span>
                      <span>
                        <b>Op:</b> {cardItemCount}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="right-process-btn d-flex align-items-center gap-2">
                    <Link
                      to="#"
                      className="btn btn-outline-primary d-flex align-items-center gap-1"
                      data-bs-toggle="modal"
                      data-bs-target="#kanbanExample"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">
                        Add Process From Templates
                      </span>
                    </Link>

                    <Link
                      to="#activityProcessOffCanvas"
                      className="btn btn-primary d-flex align-items-center gap-1"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">Add Process</span>
                    </Link>
                  </div>
                </div>

                {processDetailsLoading ? (
                  <div className="board_outer">
                    <div className="project-tasks-container d-flex overflow-x-auto pb-3 grid-view gap-2">
                      {[1, 2, 3, 4].map((item, index) => {
                        return (
                          <div
                            className="col-lg-3 d-flex flex-column gap-2"
                            key={index}
                          >
                            <div className="card-title gradient-light p-3 rounded-10 d-flex justify-content-between align-items-center mb-2">
                              <h3 className="lh-1 mb-0">
                                <span
                                  className="d-block placeholder text-gray"
                                  style={{ width: "15rem", height: "0.5rem" }}
                                ></span>
                              </h3>
                            </div>

                            <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
                              <div className="title d-flex align-items-center gap-3 justify-content-between">
                                <p className="fs-md fw-semibold">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{ width: "15rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <div className="d-flex ms-auto">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{
                                      width: "0.1rem",
                                      height: "0.5rem",
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <p className="fs-sm text-gray d-flex align-items-center gap-1 my-3">
                                <span className="d-block material-symbols-outlined icon-sm">
                                  calendar_month
                                </span>
                                <span
                                  className="d-block placeholder"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                                <span
                                  className="d-block placeholder ms-2"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <div className="d-flex align-items-center gap-3">
                                <div className="profile d-flex align-items-center gap-2">
                                  <div
                                    className="avatar rounded-circle overflow-hidden"
                                    style={{
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  >
                                    <img
                                      src={assetImages.defaultUser}
                                      alt="User"
                                      className="w-100 h-100 object-fit-cover object-center"
                                    />
                                  </div>
                                  <p className="fs-sm">
                                    <span
                                      className="d-block placeholder text-gray"
                                      style={{
                                        width: "5rem",
                                        height: "0.5rem",
                                      }}
                                    ></span>
                                  </p>
                                </div>
                                <p className="d-flex align-items-center gap-1">
                                  <span className="d-block material-symbols-outlined icon-sm">
                                    schedule
                                  </span>
                                  <span
                                    className="d-block fs-sm placeholder text-gray"
                                    style={{ width: "5rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <span
                                  className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90 placeholder"
                                  style={{ width: "5rem", height: "1rem" }}
                                ></span>
                              </div>
                            </div>
                            <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
                              <div className="title d-flex align-items-center gap-3 justify-content-between">
                                <p className="fs-md fw-semibold">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{ width: "15rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <div className="d-flex ms-auto">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{
                                      width: "0.1rem",
                                      height: "0.5rem",
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <p className="fs-sm text-gray d-flex align-items-center gap-1 my-3">
                                <span className="d-block material-symbols-outlined icon-sm">
                                  calendar_month
                                </span>
                                <span
                                  className="d-block placeholder"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                                <span
                                  className="d-block placeholder ms-2"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <div className="d-flex align-items-center gap-3">
                                <div className="profile d-flex align-items-center gap-2">
                                  <div
                                    className="avatar rounded-circle overflow-hidden"
                                    style={{
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  >
                                    <img
                                      src={assetImages.defaultUser}
                                      alt="User"
                                      className="w-100 h-100 object-fit-cover object-center"
                                    />
                                  </div>
                                  <p className="fs-sm">
                                    <span
                                      className="d-block placeholder text-gray"
                                      style={{
                                        width: "5rem",
                                        height: "0.5rem",
                                      }}
                                    ></span>
                                  </p>
                                </div>
                                <p className="d-flex align-items-center gap-1">
                                  <span className="d-block material-symbols-outlined icon-sm">
                                    schedule
                                  </span>
                                  <span
                                    className="d-block fs-sm placeholder text-gray"
                                    style={{ width: "5rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <span
                                  className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90 placeholder"
                                  style={{ width: "5rem", height: "1rem" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="board_outer">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable
                        droppableId="all-columns"
                        direction="horizontal"
                        type="column"
                      >
                        {(provided) => {
                          return (
                            <KanbanBoard
                              provided={provided}
                              kanbanData={kanbanData}
                              isProcessOwner={isProcessOwner}
                              collapseHandler={collapseHandler}
                              setCardItemCount={setCardItemCount}
                              deleteLeadHandler={deleteLeadHandler}
                              createContactChatHandler={
                                createContactChatHandler
                              }
                              showAddNewIcon={true}
                            />
                          );
                        }}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>

        <CrmLeadKanbanFilterPopup
          moduleName={moduleName}
          filterSubsribeStatus={filterSubsribeStatus}
          setFilterSubsribeStatus={setFilterSubsribeStatus}
          filterStartDate={filterStartDate}
          setfilterStartDate={setfilterStartDate}
          filterEndDate={filterEndDate}
          setfilterEndDate={setfilterEndDate}
          filterLeadName={filterLeadName}
          setFilterLeadName={setFilterLeadName}
          filterEmail={filterEmail}
          setFilterEmail={setFilterEmail}
          filterPhone={filterPhone}
          setFilterPhone={setFilterPhone}
          filterLocation={filterLocation}
          setFilterLocation={setFilterLocation}
          filterCompany={filterCompany}
          setFilterCompany={setFilterCompany}
          filterPosition={filterPosition}
          setFilterPosition={setFilterPosition}
          filterTransactionModule={filterTransactionModule}
          setFilterTransactionModule={setFilterTransactionModule}
          filterTransactionElement={filterTransactionElement}
          setFilterTransactionElement={setFilterTransactionElement}
          filterSkills={filterSkills}
          setFilterSkills={setFilterSkills}
          filterMinSalary={filterMinSalary}
          setFilterMinSalary={setFilterMinSalary}
          filterMaxSalary={filterMaxSalary}
          setFilterMaxSalary={setFilterMaxSalary}
          filterLabels={filterLabels}
          setFilterLabels={setFilterLabels}
          isFilterReset={isFilterReset}
          setIsFilterReset={setIsFilterReset}
          setIsNoDefaultFilter={setIsNoDefaultFilter}
        />

        {/* dnd modals */}
        <SaveColumnModal
          selectedProcessId={selectedProcessId}
          setSelectedProcessId={setSelectedProcessId}
          afterModalClose={afterAddNewColumn}
        />

        <SaveActivityProcessPopup
          moduleSlug={moduleSlug}
          afterPopupClose={getAllProcess}
          setSelectProcessId={() => {}}
          selectedProcessId={null}
          isTemplate={false}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <AddProcessFromTemplateModal
          moduleSlug={moduleSlug}
          afterProcessModalClose={afterProcessModalClose}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <SaveDirectAccessModal
          moduleSlug="MOD_LEAD_KANBAN"
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CrmLeadKanbanBody;
