/* eslint-disable */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import ChallengesCommonHeader from "components/ChallengeComponents/Common/Header/ChallengesCommonHeader"; 

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

import ProjectChart from "../DashboardSections/ProjectChart";
import ProjectGraph from "../DashboardSections/ProjectGraph";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import CurrentProjectList from "../DashboardSections/CurrentProjectList";
import ProjectCard from "components/ChallengeComponents/Common/Cards/ProjectCard";
import TaskCard from "components/ChallengeComponents/Common/Cards/TaskCard";
import InvoiceCard from "components/ChallengeComponents/Common/Cards/InvoiceCard";

const ChallengeDashboardBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation();
  const breadcrumbTxt = [{ title: t("Challenges") + ` / ` + t("Dashboard") }];

  const [isLoading, setIsLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get project list
  const getProjectList = async () => {
    try {
      setIsLoading(true);

      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_PROJECTS}?token=${token}&userrolestring=${userInfo.role.slug}`;

      const response = await getData(requestUrl);

      // console.log("projects >>>", response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");

        setProjectList(response.data.slice(0, 10));
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  useEffect(() => {
    getProjectList();
  }, []);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <section className="projects-wrapper bg-white pb-5">
      <ChallengesCommonHeader componentName="dashboard" />

      <div className="container-fluid px-lg-5">
        <BreadCrumb breadCrumbText={breadcrumbTxt} bottom={true} />

        {/* <!-- ========== Start upper card Section ========== --> */}
        <div className="row mb-4">
          <ProjectCard />

          <TaskCard />

          <InvoiceCard />
        </div>
        {/* <!-- ========== End upper card Section ========== --> */}

        <ProjectGraph />

        <CurrentProjectList isLoading={isLoading} projectList={projectList} />

        <ProjectChart />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    </section>
  );
};

export default ChallengeDashboardBody;
